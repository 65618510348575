<template>
<span>
    <p class="w-100 text-center text-h5 mt-4 font-weight-light">
    <v-img width="80" class="ma-3 ma-auto mb-3"
           src="https://built-storage.ams3.cdn.digitaloceanspaces.com/Built/app_name.png"></v-img>

   Create your account

  </p>
  <v-form ref="registerForm">

<v-text-field
    outlined
    autofocus
    label="First Name"
    :rules="store.state.required_rules"
    v-model="first_name"
></v-text-field>

<v-text-field
    outlined
    label="Last Name"
    :rules="store.state.required_rules"
    v-model="last_name"

></v-text-field>

<phone-number-input @input="p=>phone_number=p"></phone-number-input>

  <v-text-field
      label="Email"
      outlined
      :rules="email_rules"
      type="email"
      v-model="email"

  ></v-text-field>

  <v-text-field
      outlined
      label="Password"
      v-model="password"
      :rules="password_rules"
      :type="showPassword ? 'text' : 'password'"
      @click:append="showPassword=!showPassword"
      :append-icon="!showPassword ? 'mdi-eye-outline':'mdi-eye-off-outline'"
  ></v-text-field>

  <v-text-field
      outlined
      label="Confirm Password"
      v-model="password_confirmation"
      :type="showPassword2 ? 'text' : 'password'"
      @click:append="showPassword2=!showPassword2"
      :append-icon="!showPassword2 ? 'mdi-eye-outline':'mdi-eye-off-outline'"
      :rules="password_rules"
  ></v-text-field>

  <v-btn @click="register" :loading="loading" block rounded x-large depressed color="blue darken-4" dark>Create Account</v-btn>
  </v-form>

    <p class="mt-4 ma-3 text-center">
   Already have an account ? <v-btn text color="blue" to="/auth/login" small>login</v-btn>
  </p>


</span>
</template>
<script>
import {defineComponent} from 'vue'
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";
import store from "@/store";
import Cookies from "js-cookie";

export default defineComponent({
  name: "resgiterComponent",
  computed: {
    store() {
      return store
    }
  },
  components: {PhoneNumberInput},
  data() {
    return {
      loading: false,
      showPassword: false,
      showPassword2: false,
      pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

      email_rules: [
        (v) => !!v || "E-mail is required",
        (v) => this.pattern.test(v) || "E-mail must be valid",
      ],
      password_rules: [
        (value) => !!value || "password is Required",
      ],
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      password: "",
      password_confirmation: ""
    }
  },
  methods: {

    register() {

      if (this.$refs.registerForm.validate()) {

        this.loading = true;

        const data = {
          first_name: this.first_name,
          last_name: this.last_name,
          phone_number: this.phone_number,
          email: this.email,
          media:"Payroll-App",
          password: this.password,
          password_confirmation: this.password_confirmation
        };

        // eslint-disable-next-line no-undef
        axios.post("/auth/v2/signup", data)
            .then(res => {

              Cookies.set("token",res.data.access_token);


              location.reload();



            })
            .catch(() => {
              this.loading = false;
            })
      }
    }
  }
})
</script>

<style scoped>

</style>