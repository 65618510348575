<template>
  <span>
    <v-row dense>
      <v-col cols="4" class="mr-0 pr-0">
        <v-autocomplete outlined :items="getCountries" item-text="alpha2Code" :item-value="country" v-model="country"
          @change="
            $refs['phone-input'].focus();
          $emit('input', prefix + phone_number);
          ">
          <template v-slot:prepend-inner>
            <v-img width="30px" :src="selectedCountry ? selectedCountry.flag : ''"></v-img>
          </template>

          <template v-slot:item="{ item }">
            <flag class="mr-2" :iso="item.alpha2Code"></flag>
            {{ item.alpha2Code }}({{ item.callingCodes[0] }})
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="8" class="ml-0 pl-0">
        <v-text-field label="Phone Number" v-model="phone_number" @input="$emit('input', prefix + phone_number)"
          ref="phone-input" :prefix="prefix" outlined type="tel" :rules="employee ? '' : phone_number_rules"
          persistent-hint hint="Enter phone number excluding the leading 0"></v-text-field>
      </v-col>
    </v-row>
  </span>
</template>

<script>
import countries from "./countries.json";

export default {
  name: "PhoneNumberInput",
  props: ["default_number", "isEmailLogin", "employee"],
  data() {
    return {
      countries: [],
      country: "GH",
      phone_number: "",
      callingCode: null,
      phone_number_rules: [(value) => !!value || "Phone number is Required."],
    };
  },
  watch: {
    default_number() {
      this.phone_number = this.default_number;
      this.$emit("input", this.prefix + this.phone_number);
    }
  },
  computed: {
    selectedCountry() {
      return this.getCountries.find((country) => {
        if (country.alpha2Code === this.country && !this.callingCode) {
          this.country = country.alpha2Code
          return true
        } else if (this.callingCode && country.callingCodes[0] === this.callingCode) {
          this.country = country.alpha2Code
          this.callingCode = null
          return true
        }
        return false
      });
    },
    getCountries() {
      if (!this.isEmailLogin) {

        return countries.filter(country => ['GH', 'KE', 'SL'].includes(country.alpha2Code));

      } else {

        return countries;

      }
    },
    prefix() {
      const p = this.selectedCountry
        ? this.selectedCountry.callingCodes[0]
        : "";
      return p ? "+" + p : "";
    },
  },
  mounted() {
    if (this.default_number) {
      this.phone_number = this.default_number.slice(-9);
      this.callingCode = this.default_number.slice(1, 4)
      this.$emit("input", this.prefix + this.phone_number);
    }
    // eslint-disable-next-line no-undef
    axios.get("http://ip-api.com/json/").then((res) => {
      if (res.data.status === "success") {
        this.country = res.data.countryCode;
      }
    });
  },
};
</script>

<style scoped></style>