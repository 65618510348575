import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
import FlagIcon from "vue-flag-icon";
import VueApexCharts from "vue-apexcharts";
import Flutterwave from "vue-flutterwave";
import moment from "moment";
import { abbreviateNumber } from "@/utils";
import i18n from "./i18n";
import Vuesax from "vuesax";
import "vuesax/dist/vuesax.css";
import VueTour from "vue-tour";
import Cookies from "js-cookie";
import VueSocketIO from "vue-socket.io";
import SocketIO from "socket.io-client";
import VueIntercom from "vue-intercom";

Vue.use(Flutterwave, { publicKey: store.state.flutterwave_key });

Vue.use(FlagIcon);
Vue.use(VueApexCharts);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component("apexchart", VueApexCharts);
Vue.use(VueTour);
Vue.use(Vuesax);
Vue.use(VueIntercom, { appId: "ev5nda20" });

let prodURL = "https://soc-net.builtaccounting.com/";
Vue.use(
  new VueSocketIO({
    connection: SocketIO(prodURL),
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_",
    },
  })
);

Vue.config.productionTip = false;

new Vue({
  i18n,
  vuetify,
  store,
  render: (h) => h(App),
  router,
  computed: {
    EXTERNAL_LINK() {
      const token = Cookies.get("token");

      return this.$store.state.URL + "init?token=" + token;
    },
  },
  mounted() {
    this.$store.commit("initUser");
  },
}).$mount("#app");

Vue.filter("humanDate", (d) => {
  if (d) {
    return moment(d, "YYYY-MM-DD").format("MMM Do, YYYY");
  }
});

Vue.filter("humanDateTime", (d) => {
  if (d) {
    return moment(d).format("MMM Do YYYY, h:mm A");
  }
});

Vue.filter("absolute", (d) => {
  if (d) {
    if (!isNaN(d)) {
      return d < 0
        ? "(" +
            (Number(d) / -1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") +
            ")"
        : Number(d)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    } else {
      return d;
    }
  } else {
    return 0;
  }
});

Vue.filter("currency_symbol", (d) => {
  if (d < 0) {
    return "-" + store.state.user.current_business.currency.symbol + d / -1;
  } else {
    return store.state.user.current_business.currency.symbol + d;
  }
});

Vue.filter("toMoney", (d) => {
  if (d) {
    return Number(d)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  } else {
    return "0.00";
  }
});

Vue.filter("short_number", (d) => {
  return abbreviateNumber(d);
});
