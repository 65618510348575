import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";
import Cookies from "js-cookie";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    allowedPlans: ["starter", "enterprise", "pro", "plus", "venture"],
    showCaculator: false,
    showApps: false,
    user: null,
    initializing: false,
    showSuccess: false,
    flutterwave_key: "FLWPUBK-156ac1734b17652dfd246eaca2ae41cd-X",
    flutterwave_keyAlt: "FLWPUBK_TEST-4105a671a4bcb87e63bec80792b076f3-X",
    momo: {
      progress: false,
      requestPendding: false,
      message: "",
      failed: false,
      complete2: false,
    },
    walletAccounts: null,
    wallet: null,
    new_employee: false,
    init_state: 20,
    AllAccounts: null,
    new_payrun: false,
    showGetStarted: false,
    showGeneralMessage: false,
    removeRouter: false,
    generalMessage: null,
    paystackkey: "pk_live_7cd806cab1901f4ee5e8a838059dbe9926e0cbd5",
    paystackkey_alt: "pk_test_b30fc5741e33a63b84b5a3e6017fb82640c627d2",
    URL: "https://web.builtaccounting.com/",
    URL_alt: "https://web.builtaccounting.com/",
    baseURL: "https://web.builtaccounting.com",
    email_rules: [
      (v) => !!v || "E-mail is required",
      (v) =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "E-mail must be valid",
    ],
    required_rules: [(value) => !!value || "This field is required"],
  },
  getters: {},
  mutations: {
    initUser(state) {
      let params = new URL(document.location.toString()).searchParams;
      let token = params.get("s");
      if (token) {
        Cookies.set("token", token);
        window.location = "/";
      }
      state.initializing = true;
      // eslint-disable-next-line no-undef
      axios
        .get("/auth/user")
        .then((res) => {
          state.user = res.data;

          state.init_state = 60;

          if (state.user.has_business) {
            //
            // window.Pusher = require("pusher-js");
            //
            // window.Echo = new Echo({
            //
            //     broadcaster: "pusher",
            //     key: "1c6d43ee4770d34a4afa",
            //     cluster: "eu",
            //     namespace: "",
            //     encrypted: true,
            //     authEndpoint: "https://web.builtaccounting.com/broadcasting/auth",
            //     auth: {
            //         headers: {
            //             Authorization: "Bearer " + getAccessToken(),
            //         },
            //     },
            // });

            if (!state.user.current_business.payroll_settings) {
              state.showGetStarted = true;
            } else {
              if (
                !state.allowedPlans.includes(
                  state.user.current_business.subscription.name.toLowerCase()
                )
              ) {
                state.removeRouter = true;
              }
            }

            // eslint-disable-next-line no-undef
            axios
              .get("/v2/getallaccounts")
              .then((res) => {
                state.AllAccounts = res.data;

                state.init_state = 100;

                state.initializing = false;
              })

              // eslint-disable-next-line no-unused-vars
              .catch((error) => {});
          } else {
            state.init_state = 100;
            state.initializing = false;
            state.showGetStarted = true;
          }

          if (["login", "register", "auth"].includes(router.currentRoute.name))
            router.push({ path: "/" });
        })
        .catch(() => {
          state.initializing = false;

          if (!["login", "register", "auth"].includes(router.currentRoute.name))
            router.push({ path: "/auth" });
        });
    },
  },
  actions: {},
  modules: {},
});
