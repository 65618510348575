<template>
<span>
  <v-row>
    <v-col cols="12" sm="6" class="mx-auto">
      <v-card flat style="margin-top: 60px">
        <v-card-text>

          <v-row>
            <v-col cols="12" sm="6"
                   style="background-image: url('/img/background.png');
                   background-repeat: no-repeat;
                   background-size: cover;
                    background-blend-mode: difference;
                    border-radius: 15px;
                    text-align: center;
                    padding: 20px;
                    color: white !important;
                    vertical-align: middle;
                    transition: 0.3s ease-in-out;
">

              <div class="fill-height">

              <div class=" text-center">
                <center>

              <v-avatar style="border-radius: 15px !important;" rounded color="white" size="160" class="mt-6">

              <v-img contain src="/img/team.svg"></v-img>

              </v-avatar>

              <h1 class="ma-auto mt-4 mb-4 text-h4">Built Payroll</h1>
              <p class="mt-3 mb-6">
                Manage your employees, create and share payslips.
              </p>

               <flag style="width: 60px; margin: 5px; height: 70px" iso="GH"></flag>
               <flag style="width: 60px; margin: 5px; height: 70px" iso="KE"></flag>
               <flag style="width: 60px; margin: 5px; height: 70px" iso="SL"></flag>
                </center>

              </div>
              </div>

            </v-col>

            <v-col cols="12" sm="6">

              <router-view>
           </router-view>

            </v-col>

          </v-row>


        </v-card-text>
      </v-card>
      <p class="text-center mt-5">
        <a class="ma-3 grey--text text-decoration-none" href="https://support.built.africa/en/">Help</a>
        <a class="ma-3 grey--text text-decoration-none" href="https://built.africa/gh/termsOfService">Terms of use</a>
        <a class="ma-3 grey--text text-decoration-none" href="https://built.africa/gh/termsOfService">About</a>
        <a class="ma-3 grey--text text-decoration-none" href="https://built.africa">Home</a>
      </p>
    </v-col>
  </v-row>


</span>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "AuthComponent"
})
</script>

<style scoped>

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
</style>