<template>
  <v-container fluid v-if="showBarner">
    <v-row>
      <v-col cols="12">
        <v-card flat color="blue darken-4" dark>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" sm="2" class="text-center">
                <center>

                  <div class="text-center"
                       style="border-radius: 50%; width: 100px; height: 100px; background-color: rgba(161,212,252,0.5)">
                    <v-icon size="100" class="ma-auto mt-4" dark>mdi-lightbulb-outline</v-icon>
                  </div>
                </center>

              </v-col>
              <v-col cols="12" sm="8" class="pr-4">
                <h1 class="text-h4 white--text font-weight-light">Pay your employees instantly with just a click</h1>
                <p class="white--text mt-2 mr-3">Setup your payment account to easily pay salaries directly into bank
                  accounts of employees. We would require you to provide the registration documents of your business
                  for KYC.</p>

              </v-col>
              <v-col cols="12" sm="2">
                <v-btn @click="startSetupDialog=true" light color="amber" class="mt-6 mr-3" x-large depressed rounded
                       block>Get Started
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>
    <v-dialog
        transition="dialog-bottom-transition"
        scrollable
        persistent
        fullscreen
        v-model="startSetupDialog">
      <wallet-setup-component @done="startSetupDialog=false"></wallet-setup-component>
    </v-dialog>
  </v-container>
</template>
<script>
import {defineComponent} from 'vue'
import WalletSetupComponent from "@/components/WalletSetupComponent.vue";

export default defineComponent({
  name: "WalletBarnerCompnent",
  components: {WalletSetupComponent},
  data() {
    return {
      startSetupDialog: false,
      showBarner: false
    }
  },
  mounted() {

    axios.get("/bw/wallet")
        .then(res => {
          this.showBarner = !res.data.status;

        })

  }

})
</script>


<style scoped>

</style>