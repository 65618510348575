<template>

  <div>


    <div>
      <v-container fluid v-if="$store.state.user.current_business.subscription.guards.payroll">
        <v-card flat>
          <v-card-title>

            <v-btn v-if="!['dashboard', 'employees', 'payruns', 'settings'].includes($router.currentRoute.name)"
              @click="$router.back()" text color="blue">
              <v-icon small>mdi-arrow-left</v-icon>
              Back
            </v-btn>

          </v-card-title>

          <v-card-text style="border-radius: 15px; height: 90vh; overflow: auto;">

            <router-view v-if="CAN_USE_PAYROL"></router-view>

            <v-row dense v-else>
              <v-col cols="12" sm="4" class="mx-auto text-center">

                <center>

                  <div style="width: 80px; height: 80px; border-radius: 50%" class="red lighten-5 text-center">
                    <v-icon class="ma-4" x-large color="red">mdi-lock-outline</v-icon>
                  </div>
                </center>

                <h2 class="text-danger font-weight-light ma-5 pa-5 text-center font-weight-light mt-0">You do not have
                  access to payroll on this business, please
                  contact your admin.</h2>
              </v-col>
            </v-row>


          </v-card-text>

        </v-card>
      </v-container>


    </div>


  </div>

</template>

<script>
export default {
  name: "payrollDashboard",
  data() {
    return {
      calculatorDialog: false,
      nssf_formula: "new_method",
      pension_formula: [
        { text: "New Rate(Tiered)", value: "new_method" },
        { text: "Old Rate(KSH200)", value: "old_method" },
      ],
      tax_station: '',
      nita_number: "",
      tier3_description: 'Tier 3',
      enable_tier3: false,
      form_valid: false,
      payment_accounts: [],
      payment_accountID: null,
      tabindex: '',
      setup_dialog: false,
      e1: 1,
      sync_accounts: true,
      er_number: '',
      country_supported: true,
      tier2_trustee_number: '',
      tier3_trustee_number: '',
      tier2_trustee_id: 0,
      tier3_trustee_id: 0,
      tier2_trustees: [],
      gra_branches: [],
      gra_branch_id: null,
      company_name: '',
      tin: '',
      old_tin: '',
      progress: false,
      update_progress: false,
      required_rules: [
        v => !!v || 'required',
      ],

    }
  },
  computed: {
    alt_TIN_Lable() {
      if (this.BusinessCountry.code === 'GH') {
        return "Old TIN";
      } else if (this.BusinessCountry.code === 'KE') {
        return "NHIF Code";
      } else {
        return "";
      }

    },

    TIN_lable() {
      if (this.BusinessCountry.code === 'GH') {
        return "TIN";
      } else if (this.BusinessCountry.code === 'KE') {
        return "KRA PIN";
      } else {
        return "TIN";
      }

    },

    tax_office_label() {
      if (this.BusinessCountry.code === 'GH') {
        return "Tax Office Branch";
      } else if (this.BusinessCountry.code === 'KE') {
        return "Tax Station";
      } else {
        return "Tax office";
      }

    },
    BusinessCountry() {
      return this.$store.state.user.current_business.country;
    },
    settings() {
      return this.$store.state.user.current_business.payroll_settings;
    },

    is_country_suported() {
      return this.business.country.code === 'GH';
    },
    business() {
      return this.$store.state.user.current_business;
    },

    /**
     * @return {boolean}
     */
    CAN_USE_PAYROL() {

      if (this.$store.state.user.is_admin === 1) {
        return true;
      } else if (this.$store.state.user.invite.has_payroll === 1) {
        return true;
      }

      return false;
    }
  },
  methods: {

    initItercom() {
      this.$intercom.boot({
        user_id: this.$store.state.user.has_business
          ? this.$store.state.user.id +
          "-" +
          this.$store.state.user.current_business.country_id +
          "-" +
          this.$store.state.user.current_business.id
          : this.$store.state.user.id + "-NOB",
        name: this.$store.state.user
          ? this.$store.state.user.first_name +
          " " +
          this.$store.state.user.last_name
          : "User",
        email: this.$store.state.user.email,
        phone: this.$store.state.user.phone_number,
      });

    },

    updateSettings() {
      let formdata = new FormData();
      this.update_progress = true;

      formdata.append('er_number', this.er_number);
      formdata.append('company_name', this.company_name);
      formdata.append('tin', this.tin);
      formdata.append('old_tin', this.old_tin);
      formdata.append('gra_branch_id', this.gra_branch_id);
      formdata.append('tier2_trustee_id', this.tier2_trustee_id);
      formdata.append('tier2_trustee_number', this.tier2_trustee_number);
      formdata.append('sync_accounts', this.sync_accounts === true ? 1 : 0);
      formdata.append('payment_accountID', this.payment_accountID);
      formdata.append('nita_number', this.nita_number);
      formdata.append('nssf_formula', this.nssf_formula);
      formdata.append('tax_station', this.tax_station);

      if (this.enable_tier3) {
        formdata.append('tier3_trustee_id', this.tier3_trustee_id);
        formdata.append('tier3_trustee_number', this.tier3_trustee_number);
        formdata.append('tier3_description', this.tier3_description);
      }


      // eslint-disable-next-line no-undef
      axios.post('/payrollsettings/' + this.settings.id, formdata)
        .then(res => {
          this.$store.state.user.current_business.payroll_settings = res.data;
          this.update_progress = false;

        })
        .catch(() => {

        });

    },

    save_settings() {
      if (this.$refs.settings_form.validate()) {

        this.progress = true;
        let formdata = new FormData();

        formdata.append('er_number', this.er_number);
        formdata.append('company_name', this.company_name);
        formdata.append('tin', this.tin);
        formdata.append('old_tin', this.old_tin);
        formdata.append('gra_branch_id', this.gra_branch_id);
        formdata.append('tier2_trustee_id', this.tier2_trustee_id);
        formdata.append('tier2_trustee_number', this.tier2_trustee_number);
        formdata.append('sync_accounts', this.sync_accounts === true ? 1 : 0);
        formdata.append('payment_accountID', this.payment_accountID);
        formdata.append('nita_number', this.nita_number);
        formdata.append('nssf_formula', this.nssf_formula);
        formdata.append('tax_station', this.tax_station);

        if (this.enable_tier3) {
          formdata.append('tier3_trustee_id', this.tier3_trustee_id);
          formdata.append('tier3_trustee_number', this.tier3_trustee_number);
          formdata.append('tier3_description', this.tier3_description);
        }

        // eslint-disable-next-line no-undef
        axios.post('/payrollsettings', formdata)
          .then(res => {
            this.$store.state.user.current_business.payroll_settings = res.data;
            this.get_country_and_taxes();
            this.progress = false;
            this.e1 = 3;
            this.$store.commit('init_user');
            this.updateSettings();

          })
          .catch(() => {

          });
      }

    },

    get_country_and_taxes() {
      // eslint-disable-next-line no-undef
      axios.get('/getallaccounts')
        .then(res => {
          this.$store.state.AllAccounts.assetAccounts = res.data.assets;
          this.$store.state.AllAccounts.expenseAccounts = res.data.expenses;
          this.$store.state.AllAccounts.equityAccounts = res.data.equity;
          this.$store.state.AllAccounts.liabilityAccounts = res.data.liabilities;
          this.$store.state.AllAccounts.incomeAccounts = res.data.income;

          this.loading = false;

        })
        .catch(() => {

          this.loading = false;

        });

    },

    get_branches() {
      let business = JSON.parse(JSON.stringify(this.$store.state.user.current_business));
      this.company_name = business.name;

      this.progress = true;
      // eslint-disable-next-line no-undef
      axios.get('/grabranches')
        .then(res => {

          this.gra_branches = res.data;

          // eslint-disable-next-line no-undef
          axios.get('/tier2trustees')
            .then(res => {
              this.tier2_trustees = res.data;
              this.progress = false

            })


        });


    },
  },
  mounted() {
    this.$store.state.side_bar = false;

    let sub = this.$store.state.AllAccounts.assetAccounts.subtypes.filter(account_type => {
      return account_type.name == "Cash & Bank";
    });

    this.payment_accounts = sub[0].accounts;

    if (this.$store.state.user.current_business.payroll_settings == null) {

      if (this.CAN_USE_PAYROL) {

        this.initItercom();

        this.$store.state.first_payroll = true;
        this.setup_dialog = true;
      }

    }
  }
}
</script>

<style scoped></style>
