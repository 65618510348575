<template>
  <span>
    <v-row dense>
      <v-col cols="12" sm="8" class="grey lighten-5" style="height: 90vh; padding-top: 10em">
        <center>
          <v-img width="120" src="/img/welcome.svg"></v-img>

        </center>

        <h1 v-if="user" class="text-h4 font-weight-light text-center">Welcome Aboard {{ user.first_name }}

        </h1>
        <p class="grey--text text-center">You are almost done setting up your payroll account, let's setup your company
          profile</p>


        <center>
          <v-btn @click="showLogoutDialog = true" color="red" text x-small>
            Logout
          </v-btn>

        </center>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card flat>
          <v-card-text class="text-center">
            <v-stepper elevation="0" v-model="step">
              <v-stepper-items>
                <v-stepper-content step="1">

                  <center>
                    <photo-selector-component @updated="p => logoURL = p" directory="logo"></photo-selector-component>

                    <span class="d-block">company logo</span>

                  </center>

                  <v-form ref="registerForm" class="mt-3">

                    <v-text-field label="Company Name" outlined :disabled="loading" type="text"
                      :rules="$store.state.required_rules" v-model="name"></v-text-field>



                    <v-text-field label="Phone Number" outlined :disabled="loading" type="tel"
                      :rules="$store.state.required_rules" v-model="phone_number"></v-text-field>




                    <v-textarea label="Address" rows="2" auto-grow outlined :disabled="loading"
                      v-model="address"></v-textarea>



                    <v-autocomplete label="Industry" outlined :disabled="loading" :items="industries" item-value="id"
                      item-text="name" v-model="industry_id" :rules="$store.state.required_rules"></v-autocomplete>


                    <p class="font-weight-black w-100 text-left">Select your country</p>


                    <v-radio-group :rules="$store.state.required_rules" v-model="countryCode">
                      <v-row>

                        <v-col v-for="country in countries" :key="country.code" cols="12" sm="4">

                          <v-radio :label="`Radio ${country.code}`" :value="country.code"
                            active-class="grey lighten-4 pa-3 "
                            style="transition: 0.2s ease-in-out; border-radius: 15px;" on-icon="mdi-check"
                            class="border" :off-icon="null" :disabled="loading">
                            <template v-slot:label>
                              <flag style="width: 80px; border-radius: 15px!important; height: 60px"
                                :iso="country.code"></flag>
                            </template>
                          </v-radio>
                        </v-col>
                      </v-row>


                    </v-radio-group>

                    <v-btn :loading="loading" @click="createBusiness" class="mt-3" color="blue darken-4" dark x-large
                      block depressed rounded>Continue <v-icon>mdi-arrow-right</v-icon></v-btn>
                  </v-form>

                </v-stepper-content>
                <v-stepper-content step="2">

                  <h1 v-if="currentBusiness" class="text-h5 font-weight-light">Compliance profile for {{
                    currentBusiness.name }}</h1>
                  <p class="grey--text">Let's setup country specific compliance profile for your company</p>

                  <v-skeleton-loader type="list-item@8" v-if="!currentBusiness"></v-skeleton-loader>

                  <v-card-text v-else style="height: 75vh; overflow: auto">

                    <v-form ref="payrollForm">

                      <v-text-field label="Registered Name" v-model="name" :rules="$store.state.required_rules"
                        outlined></v-text-field>


                      <v-text-field :label="currentBusiness.payroll_metadata.er_lable" v-model="ER_number"
                        :rules="$store.state.required_rules" outlined></v-text-field>

                      <v-text-field outlined :label="TINLable" :rules="$store.state.required_rules"
                        v-model="TIN_number"></v-text-field>


                      <v-text-field outlined label="NITA Number" v-model="nita_number"
                        v-if="currentBusiness.country_id == 113"></v-text-field>

                      <v-text-field outlined label="NHIF Code" v-model="old_tin"
                        v-if="currentBusiness.country_id == 113"></v-text-field>


                      <v-autocomplete label="Tax Office" :items="taxOffices" item-value="id" item-text="branch"
                        v-model="tax_office_id" v-if="['GH', 'SL'].includes(currentBusiness.country.code)" outlined
                        :rules="$store.state.required_rules"></v-autocomplete>

                      <v-autocomplete label="Office Station" :items="taxOffices" item-value="id" item-text="branch"
                        v-model="tax_office_id" v-if="currentBusiness.country_id == 113" outlined
                        :rules="$store.state.required_rules"></v-autocomplete>

                      <v-select outlined label="How do you compute NSSF?" :items="nssf_fomula_list"
                        v-model="nssf_formula" v-if="currentBusiness.country_id == 113"></v-select>

                      <v-autocomplete label="Tier2 Trustee" :items="trustees" item-value="id" item-text="name"
                        v-model="trustee_id" outlined :rules="$store.state.required_rules"
                        v-if="['GH'].includes(currentBusiness.country.code)"></v-autocomplete>


                      <v-text-field outlined label="Tier2 Trustee Number" type="text" v-model="trustee_number"
                        v-if="currentBusiness.country_id == 81"></v-text-field>

                      <v-row dense>
                        <v-col cols="12">
                          <v-checkbox class="ml-3"
                            hint="Your payslips and other activities would be recorded in the ledger for accounting"
                            persistent-hint v-model="sync_Accounts" label="Sync with accounts"></v-checkbox>

                        </v-col>
                        <v-col cols="12">
                          <v-checkbox class="ml-3" v-if="currentBusiness.country_id == 81" v-model="showTier3"
                            label="We contribute tier3"></v-checkbox>

                        </v-col>
                      </v-row>

                      <v-autocomplete class="mt-4" label="Tier3 Trustee" :items="trustees" item-value="id"
                        item-text="name" v-if="showTier3" outlined v-model="tier3_trustee_id"
                        :rules="$store.state.required_rules"></v-autocomplete>

                      <v-text-field outlined label="Tier 3 description" :rules="$store.state.required_rules"
                        v-if="showTier3" v-model="tier3_description"></v-text-field>

                      <v-text-field outlined label="Tier 3 trustee Number" :rules="$store.state.required_rules"
                        v-if="showTier3" v-model="tier3_trustee_number"></v-text-field>



                    </v-form>
                  </v-card-text>

                  <v-btn @click="setupPayroll" :loading="loading" class="mt-4" v-if="currentBusiness" color="green" dark
                    block x-large rounded depressed>Finish</v-btn>

                </v-stepper-content>

              </v-stepper-items>
            </v-stepper>


          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog :persistent="progress" v-model="showLogoutDialog" width="400">
      <v-card>
        <v-card-title>Confirm Logout</v-card-title>
        <v-card-text>
          <p class="text-h4 font-weight-light">Do you want to logout of your account? </p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="logout" :loading="progress" block x-large rounded depressed dark>Logout</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { defineComponent } from 'vue'
import store from "@/store";
import PhotoSelectorComponent from "@/components/PhotoSelectorComponent.vue";

export default defineComponent({
  name: "CreateBusinessComponent",
  components: { PhotoSelectorComponent },

  data() {
    return {
      showLogoutDialog: false,
      progress: false,
      nssf_fomula_list: [
        {
          value: "new_method",
          text: "New Rate(Tiered)"
        },
        {
          value: "old_method",
          text: "Old Rate(KSH200)"
        }
      ],
      logoURL: "",
      step: 1,
      old_tin: "",
      countryCode: "",
      trustee_number: "",
      tier3_trustee_number: "",
      tier3_description: "",
      loading: false,
      industries: [],
      currencies: [],
      realCountries: [],
      taxOffices: [],
      tax_office_id: null,
      tier3_trustee_id: 0,
      trustee_id: 0,
      trustees: [],
      industry_id: null,
      sync_Accounts: false,
      showTier3: false,
      TIN_number: "",
      ER_number: "",
      name: "",
      phone_number: "",
      nssf_formula: "new_method",
      nita_number: "",
      tax_station: null,
      currentBusiness: null,
      address: "",
      countries: [
        {
          code: "GH",
          name: "Ghana"
        },
        {
          code: "KE",
          name: "Kenya"
        },
        {
          code: "SL",
          name: "Sierra Leone"
        }

      ]
    }
  },

  computed: {
    TINLable() {

      if (this.currentBusiness.country_id == 81) {

        return "TIN Number"
      }


      if (this.currentBusiness.country_id == 113) {

        return "KRA PIN"
      }


      return "TIN Number"

    },
    user() {
      return store.state.user;
    },
    selectedCountry() {

      return this.realCountries.find(country => country.code.toLowerCase() === this.countryCode.toLowerCase());

    },
    selectedCurrency() {

      return this.currencies.find(currency => currency.country.toLowerCase() === this.selectedCountry.name.toLowerCase());


    },
  },
  methods: {

    logout() {
      this.progress = true;
      axios.get("/auth/logout")
        .then(() => {
          this.progress = false;
          location.reload();
        })

    },
    setupPayroll() {

      if (this.$refs.payrollForm.validate()) {

        this.loading = true;

        this.tax_station = this.tax_office_id;
        // this.tier3_trustee_id = this.tax_office_id;
        // this.trustee_id = this.tax_office_id;

        const data = {
          company_name: this.name,
          sync_accounts: this.sync_Accounts,
          tin: this.TIN_number,
          er_number: this.ER_number,
          gra_branch_id: this.tax_office_id,
          tier2_trustee_id: this.trustee_id,
          tier2_trustee_number: this.trustee_number,
          tier3_trustee_id: this.tier3_trustee_id,
          tier3_trustee_number: this.tier3_trustee_number,
          tier3_description: this.tier3_description,
          nssf_formula: this.nssf_formula,
          nita_number: this.nita_number,
          tax_station: this.tax_station

        };

        // eslint-disable-next-line no-undef
        axios.post("/payrollsettings", data)
          .then(() => {

            location.reload();

          })
          .catch(() => {

            this.loading = false;

          })


      }

    },
    getUser() {

      // eslint-disable-next-line no-undef
      axios.get("/auth/user")
        .then(res => {

          this.currentBusiness = res.data.current_business;

        });

    },

    getTaxOffices() {

      // eslint-disable-next-line no-undef
      axios.get("/grabranches")
        .then(res => {
          this.taxOffices = res.data;
        })

    },
    getTrustees() {
      // eslint-disable-next-line no-undef
      axios.get("/tier2trustees")
        .then(res => {
          this.trustees = res.data;
        })


    },

    createBusiness() {

      if (this.$refs.registerForm.validate()) {

        this.loading = true;
        const formData = new FormData();

        formData.append("name", this.name);
        formData.append("phone_number", this.phone_number);
        formData.append("address", this.address);
        formData.append("industry_id", this.industry_id);
        formData.append("currency_id", this.selectedCurrency.id);
        formData.append("country_id", this.selectedCountry.id);
        formData.append("active", 1);
        formData.append("logoURL", this.logoURL);

        // eslint-disable-next-line no-undef
        axios.post("/createbusiness", formData)
          .then(() => {
            this.getTrustees();
            this.getTaxOffices();
            this.getUser();

            this.loading = false;
            this.step = 2;

          })
          .catch(() => {

            this.loading = false;

          })


      }


    },
    getIndustries() {
      // eslint-disable-next-line no-undef
      axios.get("/getindustries")
        .then(res => {
          this.industries = res.data;
        })
    },
    getCurrencies() {
      // eslint-disable-next-line no-undef
      axios.get("/getcurrencies")
        .then(res => {
          this.currencies = res.data;
        })
    },
    getCountries() {
      // eslint-disable-next-line no-undef
      axios.get("/getcountries")
        .then(res => {
          this.realCountries = res.data;
        })
    }
  },
  mounted() {
    this.getIndustries();
    this.getCurrencies();
    this.getCountries();

    if (!this.user.current_business.payroll_settings) {
      this.getTrustees();
      this.getTaxOffices();
      this.currentBusiness = this.user.current_business;
      this.name = this.currentBusiness.name;
      this.step = 2;
      this.loading = false;
      this.sync_Accounts = true;

    }
  }
})
</script>

<style scoped></style>