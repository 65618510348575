"use strict";

import Vue from "vue";
import axios from "axios";
import Cookies from "js-cookie";
import store from "@/store";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// eslint-disable-next-line no-undef
export const token = Cookies.get("token");

axios.defaults.headers.common["Authorization"] = "Bearer " + token;
axios.defaults.headers.post["Content-Type"] = "application/JSON";

let config = {
  timeout: 60 * 1000,
  withCredentials: true,
  baseURL: "https://web.builtaccounting.com/api",
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    // if (error.response.status === 401) {
    //   if (
    //     window.location.pathname !== "/auth/login" &&
    //     window.location.pathname !== "/auth/signup" &&
    //     window.location.pathname !== "/init"
    //   ) {
    //     // if (store.state.user && store.state.user.user_infor){
    //     //   if (!store.state.idle_dialog) {
    //     //     store.state.idle_dialog = true;
    //     //   }
    //     // }else {
    //     window.location = "/auth/login";
    //     // }
    //   }
    // }

    // if (error.response.status === 306) {
    //   store.state.global_error = true;
    //   store.state.global_error_message = error.response.data;
    // }

    /**
     * Automatically throw 422 errors
     */
    //
    if (error.response.status === 422) {
      store.state.generalMessage = error.response.data;
      store.state.showGeneralMessage = false;
      store.state.showGeneralMessage = true;
    } else if (error.response.status === 403) {
      /**
       * subscription guard forbidden from backend
       */
      store.state.generalMessage = {
        message: error.response.data.message
          ? error.response.data.message
          : "Something went wrong, try again",
        errors: [],
      };
      store.state.showGeneralMessage = false;
      store.state.showGeneralMessage = true;
    } else if (error.response.status === 302) {
      store.state.generalMessage = {
        message: error.response.data,
        errors: [],
      };
      store.state.showGeneralMessage = false;
      store.state.showGeneralMessage = true;
    } else if (error.response.status === 500) {
      store.state.generalMessage = {
        message: "Something went wrong. Could not complete your action.",
        errors: [],
      };
      store.state.showGeneralMessage = false;
      store.state.showGeneralMessage = true;
    } else {
      store.state.generalMessage = {
        message: error.response.data.message
          ? error.response.data.message
          : "Something went wrong, try again",
        errors: [],
      };
      store.state.showGeneralMessage = false;
      store.state.showGeneralMessage = true;
    }

    return Promise.reject(error);
  }
);

Plugin.install = function (Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

window.axios = _axios;

Vue.use(Plugin);

export default Plugin;
