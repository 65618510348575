<template>

  <div>


    <v-menu
        transition="slide-y-transition"
        bottom
    >
      <template v-slot:activator="{ on }">

        <v-btn
            id="payrollCompanyButton"
            :color="$vuetify.theme.isDark ? 'black' : 'white'"
            rounded
            v-on="on"
            depressed
        >
          {{ $store.state.user.current_business.payroll_settings.Company_name }}
          <v-icon color="green">mdi-check</v-icon>
          <v-icon class="ml-3">mdi-chevron-down</v-icon>

        </v-btn>
      </template>

      <v-list>
        <v-list-item
            @click="setup_dialog=true"
            class="border-bottom"
            color="blue"
        >
          <v-list-item-title>
            <v-icon>mdi-plus</v-icon>
            New
          </v-list-item-title>
        </v-list-item>
        <v-list-item
            v-for="company in companies"
            :key="company.id"
            @click="comfirm_switch(company)"
        >
          <v-list-item-title>{{ company.Company_name }}</v-list-item-title>
        </v-list-item>

      </v-list>

    </v-menu>


    <v-dialog
        persistent
        scrollable
        width="500"
        :fullscreen="$vuetify.breakpoint.mobile"
        v-model="setup_dialog"
    >
      <v-card>
        <v-card-title class="font-weight-light">
          Create a new payroll instance
          <v-spacer></v-spacer>
          <v-btn @click="setup_dialog=false;" color="red lighten-5 red--text" depressed rounded>close
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="settings_form" :value="form_valid" @keyup.native.enter="save_settings($event)">
            <v-row dense>
              <v-col cols="12" sm="8">
                <v-text-field
                    outlined
                    label="Company Name"
                    v-model="company_name"
                    autofocus
                    hint=" Strictly Required"
                    persistent-hint
                    :rules="required_rules"
                >
                </v-text-field>

              </v-col>

              <v-col cols="12" sm="4">
                <v-text-field
                    outlined
                    :label="$store.state.user.current_business.payroll_metadata.er_lable"
                    v-model="er_number"
                    hint=" Strictly Required"
                    persistent-hint
                    :rules="required_rules"
                >
                </v-text-field>

              </v-col>


              <v-col cols="12">
                <v-text-field
                    outlined
                    :label="TIN_lable"
                    v-model="tin"
                    hint="Tax Identification Number, Required"
                    persistent-hint
                    :rules="required_rules"

                >
                </v-text-field>

              </v-col>

              <v-col cols="12" v-if="is_country_suported">
                <v-text-field
                    outlined
                    :label="alt_TIN_Lable"
                    v-model="old_tin"
                    :rules="business.country.code=== 'KE' ? required_rules : []"

                >
                </v-text-field>

              </v-col>

              <v-col cols="12" v-if="business.country.code=== 'KE'">
                <v-text-field
                    outlined
                    label="NITA Number"
                    v-model="nita_number"
                    persistent-hint
                >
                </v-text-field>

              </v-col>

              <v-col cols="12" sm="6" v-if="business.country.code=== 'KE'">
                <v-select
                    :items="pension_formula"
                    outlined
                    label="How do you compute NSSF?"
                    v-model="nssf_formula"
                    persistent-hint
                    hint="This would determine how NSSF would be computed when processing your payslips"
                    :rules="required_rules"
                    auto-select-first
                >
                </v-select>

              </v-col>

              <v-col cols="12" sm="6">
                <v-autocomplete
                    item-text="branch"
                    item-value="id"
                    :items="gra_branches"
                    outlined
                    :label="tax_office_label"
                    v-model="gra_branch_id"
                    hint="Your revenue Authority Branch, Required"
                    persistent-hint
                    :rules="required_rules"
                    auto-select-first
                >

                </v-autocomplete>

              </v-col>


              <v-col cols="12" sm="6">
                <v-autocomplete
                    item-text="name"
                    item-value="id"
                    :items="tier2_trustees"
                    outlined
                    label="Tier 2 Trustee"
                    v-model="tier2_trustee_id"
                    hint="Your associate tier 2 trustee, Required"
                    :rules="required_rules"
                    v-if="tier2_trustees.length>0"

                >
                </v-autocomplete>

              </v-col>


              <v-col cols="12" sm="6">
                <v-text-field
                    outlined
                    label="Tier 2 Trustee Number"
                    v-model="tier2_trustee_number"
                    hint="Your associate tier 2 trustee Required"
                    :rules="required_rules"
                    v-if="tier2_trustees.length>0"

                >
                </v-text-field>

              </v-col>

              <v-col cols="12" sm="6">
                <v-switch
                    color="blue"
                    label="We contribute Tier3"
                    v-model="enable_tier3"
                    v-if="business.country.code==='GH'"

                ></v-switch>
              </v-col>
              <v-col cols="12" sm="12" v-if="enable_tier3">
                <v-text-field
                    label="Tier3 Description"
                    outlined
                    v-model="tier3_description"

                ></v-text-field>
              </v-col>


              <v-col cols="12" sm="12" v-if="enable_tier3">
                <v-autocomplete
                    item-text="name"
                    item-value="id"
                    :items="tier2_trustees"
                    outlined
                    label="Tier 3 Trustee"
                    v-model="tier3_trustee_id"
                    hint="Your associate tier 3 trustee"
                    persistent-hint
                    :rules="required_rules"
                    v-if="tier2_trustees.length>0"

                >
                </v-autocomplete>

              </v-col>


              <v-col cols="12" sm="12" v-if="enable_tier3">
                <v-text-field
                    outlined
                    label="Tier 3 Trustee Number"
                    v-model="tier3_trustee_number"
                    hint="Your associate tier 2 trustee Required"
                    persistent-hint
                    :rules="required_rules"
                    v-if="tier2_trustees.length>0"

                >
                </v-text-field>

              </v-col>

            </v-row>

          </v-form>


        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              :loading="progress"
              color="blue darken-4"
              dark @click="save_settings"
              rounded
              depressed
              block
              x-large
          >Create Payroll Instance
          </v-btn>

        </v-card-actions>
      </v-card>

    </v-dialog>

    <v-dialog width="400" v-model="switch_modal">
      <v-card>
        <v-card-title>Confirm Switch</v-card-title>
        <v-card-text>
          Are you sure you want to switch to {{ name }} ?
        </v-card-text>
        <v-card-actions>
          <v-btn x-large block rounded depressed color="blue darken-4" dark :loading="progress" @click="switch_company()">Change
            Payroll Company
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

  </div>

</template>

<script>
export default {
  name: "PayrollCompaniesComponent",
  data() {
    return {
      nssf_formula: "new_method",
      pension_formula: [
        {text: "New Method", value: "new_method"},
        {text: "Old Method", value: "old_method"},
      ],
      tax_station:'',

      tier3_description: 'Tier3',
      tier3_trustee_number: '',
      tier3_trustee_id: 0,
      enable_tier3: false,
      switch_modal: false,
      progress: true,
      companies: [],
      form_valid: false,
      payment_accounts: [],
      payment_accountID: null,
      tabindex: '',
      setup_dialog: false,
      e1: 1,
      sync_accounts: false,
      er_number: '',
      country_supported: true,
      tier2_trustee_number: '',
      tier2_trustee_id: 0,
      tier2_trustees: [],
      gra_branches: [],
      gra_branch_id: null,
      id: null,
      company_name: '',
      name: '',
      tin: '',
      old_tin: '',
      nita_number: "",

      required_rules: [
        v => !!v || 'required',
      ],
    }
  },
  computed: {
    tax_office_label() {
      if (this.business.country.code === 'GH') {
        return "Tax Office Branch";
      } else if (this.business.country.code === 'KE') {
        return "Tax Station";
      } else {
        return "";
      }

    },
    alt_TIN_Lable() {
      if (this.business.country.code === 'GH') {
        return "Old TIN";
      } else if (this.business.country.code === 'KE') {
        return "NHIF Code";
      } else {
        return "";
      }

    },
    TIN_lable() {
      if (this.business.country.code === 'GH') {
        return "TIN";
      } else if (this.business.country.code === 'KE') {
        return "KRA PIN";
      } else {
        return "";
      }

    },
    is_country_suported() {
      return this.business.country.code === 'GH' || 'KE';
    },
    business() {
      return this.$store.state.user.current_business;
    }
  },
  methods: {
    switch_company() {

      this.progress = true;
      // eslint-disable-next-line no-undef
      axios.get('/activatepayrollsettings/' + this.id)
          .then(() => {
            this.$router.push("/dashboard");
            window.location.reload();

          });

    },
    comfirm_switch(company) {
      this.id = company.id;
      this.name = company.Company_name;
      this.switch_modal = true;

    },
    get_companies() {
      // eslint-disable-next-line no-undef
      axios.get('payrollsettings')
          .then(res => {
            this.companies = res.data;
            this.progress = false;
          })
          .catch(() => {
            this.progress = false;
          })


    },
    get_branches() {
      let business = JSON.parse(JSON.stringify(this.$store.state.user.current_business));
      this.company_name = business.name;

      this.progress = true;
      // eslint-disable-next-line no-undef
      axios.get('/grabranches')
          .then(res => {

            this.gra_branches = res.data;

            // eslint-disable-next-line no-undef
            axios.get('/tier2trustees')
                .then(res => {
                  this.tier2_trustees = res.data;
                  this.progress = false

                })


          });


    },
    save_settings() {
      if (this.$refs.settings_form.validate()) {

        this.progress = true;
        let formdata = new FormData();

        formdata.append('er_number', this.er_number);
        formdata.append('company_name', this.company_name);
        formdata.append('tin', this.tin);
        formdata.append('old_tin', this.old_tin);
        formdata.append('gra_branch_id', this.gra_branch_id);
        formdata.append('tier2_trustee_id', this.tier2_trustee_id);
        formdata.append('tier3_trustee_id', this.tier3_trustee_id);
        formdata.append('tier2_trustee_number', this.tier2_trustee_number);
        formdata.append('tier3_trustee_number', this.tier3_trustee_number);
        formdata.append('tier3_description', this.tier3_description);
        formdata.append('sync_accounts', this.sync_accounts);
        formdata.append('payment_accountID', this.payment_accountID);
        formdata.append('nita_number', this.nita_number);
        formdata.append('nssf_formula', this.nssf_formula);
        formdata.append('tax_station', this.tax_station);
        // eslint-disable-next-line no-undef
        axios.post('/payrollsettings', formdata)
            .then(res => {

              console.log(res.data);

              this.$store.state.user.current_business.payroll_settings = res.data;
              // window.location.reload();
              this.progress = false;
            })
            .catch(() => {

            });
      }

    },
  },
  mounted() {
    this.get_companies();
    this.get_branches();
  }
}
</script>

<style scoped>

</style>