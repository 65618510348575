<template>
  <v-card flat>
    <v-card-title>
      <v-btn text rounded @click="$emit('back')">
        <v-icon>mdi-arrow-left</v-icon>
        {{ $t("main.back") }}
      </v-btn>
      <v-spacer></v-spacer>
      <h1 class="font-weight-light">Gross-to-net calculator (
        <flag iso="SL"></flag>
        Sierra Leone)
      </h1>
    </v-card-title>
    <v-card-text>
      <v-row>

        <v-col cols="4">
          <v-form ref="payForm">
            <v-row dense>

              <v-col cols="12">

                <v-menu ref="menu1" v-model="dateMenu" :close-on-content-click="false" transition="scale-transition"
                  offset-y max-width="290px" min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field dense outlined label="DATE/PERIOD" readonly v-model="date" v-on="on" v-bind="attrs"
                      persistent-hint :disabled="progress"
                      hint="Due to changing rules, this date would be used to determine how to handle the calculations"></v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title @input="dateMenu = false" :disabled="progress"></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12">
                <v-text-field autofocus dense outlined label="BASIC SALARY" type="number" v-model="basic_amount"
                  :rules="$store.state.requiredRule" :disabled="progress" :prefix="currency"></v-text-field>
              </v-col>
              <v-col cols="12">

                <v-text-field dense outlined label="ALLOWANCE" type="number" v-model="allowance" :disabled="progress"
                  :prefix="currency"></v-text-field>

                <v-text-field dense outlined label="BONUS" type="number" v-model="bonus" :disabled="progress"
                  :prefix="currency"></v-text-field>

              </v-col>

              <v-col cols="12">
                <v-text-field dense outlined label="REIMBURSEMENT" type="number" v-model="reimbursement"
                  :disabled="progress" :prefix="currency"></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-select dense outlined label="RESIDENTIAL STATUS" :items="statuses" v-model="status"
                  :disabled="progress"></v-select>
              </v-col>


              <v-col cols="12" v-if="status === 'resident'">
                <v-row dense>
                  <v-col cols="8">
                    <h3 class="font-weight-light">
                      Deduct NASSIT:
                    </h3>
                  </v-col>
                  <v-col cols="4">
                    <v-switch :disabled="progress" v-model="deduct_pension" flat inset color="blue darken-4"
                      class="ma-0" dense></v-switch>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12">
                <v-row dense>
                  <v-col cols="8">
                    <h3 class="font-weight-light">
                      Deduct income tax:
                    </h3>
                  </v-col>
                  <v-col cols="4">
                    <v-switch :disabled="progress" v-model="deduct_paye" flat inset color="blue darken-4" class="ma-0"
                      dense></v-switch>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-btn :loading="progress" @click="calculate()" large color="green" dark rounded block>Calculate</v-btn>
              </v-col>

            </v-row>

          </v-form>
        </v-col>
        <v-divider vertical></v-divider>

        <v-col cols="8">
          <v-simple-table class="table table-bordered" fixed-header height="90vh">

            <tbody>

              <tr>
                <td>
                  <h3 class="font-weight-bold">Basic Salary:</h3>

                </td>
                <td class="text-right">

                  <h3 v-if="result">{{ currency }}{{ result.basic_pay | toMoney }}</h3>
                  <h3 v-else>{{ currency }}{{ 0 | toMoney }}</h3>

                </td>
              </tr>


              <tr>
                <td>
                  <h3 class="font-weight-bold">Taxable Income:</h3>
                </td>
                <td class="text-right">
                  <h3 v-if="result">{{ currency }}{{ result.taxable_income | toMoney }}</h3>
                  <h3 v-else>{{ currency }}{{ 0 | toMoney }}</h3>
                </td>

              </tr>
              <tr>
                <td colspan="2" class="bg-light text-center">
                  <i>Earnings</i>

                </td>

              </tr>

              <tr>
                <td>
                  <h3 class="font-weight-bold">Allowance:</h3>

                </td>
                <td class="text-right">
                  <h3 v-if="result">{{ currency }}{{ result.allowance | toMoney }}</h3>
                  <h3 v-else>{{ currency }}{{ 0 | toMoney }}</h3>
                </td>

              </tr>
              <tr>
                <td>
                  <h3 class="font-weight-bold">Bonus:</h3>

                </td>
                <td class="text-right">
                  <h3 v-if="result">{{ currency }}{{ result.bonus | toMoney }}</h3>
                  <h3 v-else>{{ currency }}{{ 0 | toMoney }}</h3>
                </td>

              </tr>

              <tr>
                <td>
                  <h3 class="font-weight-bold">Reimbursement:</h3>
                </td>
                <td class="text-right">
                  <h3 v-if="result">{{ currency }}{{ result.reimbursement | toMoney }}</h3>
                  <h3 v-else>{{ currency }}{{ 0 | toMoney }}</h3>
                </td>

              </tr>


              <tr>
                <td>
                  <h3 class="font-weight-bold">Gross Salary:</h3>
                </td>
                <td class="text-right">
                  <h3 v-if="result">{{ currency }}{{ result.gross_pay | toMoney }}</h3>
                  <h3 v-else>{{ currency }}{{ 0 | toMoney }}</h3>
                </td>

              </tr>
              <tr>
                <td colspan="2" class="bg-light text-center">
                  <i>Deductions</i>

                </td>

              </tr>

              <tr>
                <td>
                  <h3 class="font-weight-bold">P.A.Y.E:</h3>
                </td>
                <td class="text-right">
                  <h3 v-if="result">{{ currency }}{{ result.paye | toMoney }}</h3>
                  <h3 v-else>{{ currency }}{{ 0 | toMoney }}</h3>
                </td>
              </tr>

              <tr>
                <td>
                  <h3 class="font-weight-bold">NASSIT :</h3>
                </td>
                <td class="text-right">
                  <h3 v-if="result">{{ currency }}{{ result.nassit | toMoney }}</h3>
                  <h3 v-else>{{ currency }}{{ 0 | toMoney }}</h3>
                </td>

              </tr>

              <tr>
                <td>
                  <h3 class="font-weight-bold">Total Deductions:</h3>
                </td>
                <td class="text-right">
                  <h3 v-if="result">{{ currency }}{{ result.total_deductions | toMoney }}</h3>
                  <h3 v-else>{{ currency }}{{ 0 | toMoney }}</h3>
                </td>

              </tr>

              <tr class="bg-light">
                <td>
                  <h2 class="font-weight-light">Net Pay:</h2>
                </td>
                <td class="text-right">
                  <h3 v-if="result">{{ currency }}{{ result.net_pay | toMoney }}</h3>
                  <h3 v-else>{{ currency }}{{ 0 | toMoney }}</h3>
                </td>

              </tr>

            </tbody>
          </v-simple-table>

        </v-col>
      </v-row>

      <v-snackbar color="red" v-model="error">{{ error_message }}</v-snackbar>


    </v-card-text>
  </v-card>

</template>

<script>
import moment from "moment";

export default {
  name: "SLPayroll",
  data() {
    return {
      error: false,
      error_message: "",
      result: null,
      allowance: 0,
      bonus: 0,
      basic_amount: 0,
      reimbursement: 0,
      date: "",
      dateMenu: false,
      status: "resident",
      deduct_pension: false,
      deduct_paye: false,
      progress: false,
      statuses: [
        {
          text: "Resident",
          value: "resident"
        },
        {
          text: "Non-resident",
          value: "nonresident"
        },

      ]
    }

  },
  computed: {
    business() {
      return this.$store.state.user.current_business;
    },
    currency() {
      return this.business.currency.code;
    }
  },
  methods: {
    calculate() {
      if (this.$refs.payForm.validate()) {
        this.progress = true;
        const formData = new FormData();
        formData.append("basic_pay", this.basic_amount);
        formData.append("bonus", this.bonus);
        formData.append("allowance", this.allowance);
        formData.append("status", this.status);
        formData.append("date", this.date);
        formData.append("deduct_pension", this.deduct_pension);
        formData.append("deduct_paye", this.deduct_paye);
        formData.append("reimbursement", this.reimbursement);
        // eslint-disable-next-line no-undef
        axios.post("/payroll/sl/gross-to-net", formData)
          .then(res => {
            this.result = res.data.data;

            this.progress = false;

          })
          .catch(() => {

            this.progress = false;
            // this.error_message = "Something went wrong, please try again";
            // this.error = true;


          });


      }

    }
  },
  mounted() {
    this.date = moment().format("Y-MM-DD");
  }
}
</script>

<style scoped></style>