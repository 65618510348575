<template>
  <span>
    <p class="w-100 text-center text-h6 mt-4 font-weight-light">
      <v-img width="80" class="ma-3 ma-auto mb-3"
        src="https://built-storage.ams3.cdn.digitaloceanspaces.com/Built/app_name.png"></v-img>

      Log on with your Built Account

    </p>
    <v-form ref="loginForm">

      <v-text-field outlined label="Email" type="email" v-model="email" autofocus :rules="email_rules"
        @keydown.enter="login"></v-text-field>

      <v-text-field outlined label="Password" v-model="password" @keydown.enter="login" :rules="password_rules"
        :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword"
        :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"></v-text-field>
      <v-btn @click="login()" :loading="loading" class="mt-3" dark color="blue darken-4" x-large rounded depressed
        block>Login</v-btn>
    </v-form>

    <p class="mt-4 ma-3 text-center">
      Don't have an account ? <v-btn text color="blue" to="/auth/register" small>Register</v-btn>
    </p>

    <p class=" ma-3 text-center">
      Forgot your password ? <v-btn text color="blue" href="https://web.builtaccounting.com/passwordreset"
        target="_blank" small>Reset Password</v-btn>
    </p>

  </span>
</template>

<script>
import { defineComponent } from 'vue'
import store from "@/store";
import Cookies from "js-cookie";

export default defineComponent({
  name: "loginComponent",
  data() {
    return {
      showPassword: false,
      loading: false,
      email: "",
      password: "",
      email_rules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      password_rules: [
        (value) => !!value || "password is Required",
      ]
    }
  },
  methods: {
    login() {

      if (this.$refs.loginForm.validate()) {
        this.loading = true;

        const data = {
          email: this.email,
          password: this.password
        };

        // eslint-disable-next-line no-undef
        axios.post("/auth/login", data)
          .then(res => {


            store.state.user = res.data.user;
            // eslint-disable-next-line no-undef
            Cookies.set('token', res.data.access_token);

            this.loading = false;

            location.reload();

          })
          .catch(() => {
            this.loading = false;
          })

      }
    }
  }
})
</script>


<style scoped></style>