<template>

  <v-snackbar
      color="red"
      top
      right
      rounded="lg"
      :timeout="4000"
      v-if="$store.state.generalMessage"
      v-model="$store.state.showGeneralMessage"
      transition="scroll-x-transition"
  >

    <h3>{{ (typeof $store.state.generalMessage.message)=="string" ?  $store.state.generalMessage.message : $store.state.generalMessage.message.message }}</h3>

    <ul
        v-for="key in keys"
        :key="key"
    >

      <li
          v-if="$store.state.generalMessage && $store.state.generalMessage.errors"

      >{{$store.state.generalMessage.errors[key][0]}}</li>

    </ul>

    <template v-slot:action="{ attrs }">
      <v-btn
          text
          dark
          v-bind="attrs"
          color="red"
          @click="$store.state.showGeneralMessage = false"
          rounded
      >
        Okay
      </v-btn>
    </template>
  </v-snackbar>

</template>

<script>

export default {
  name: "GeneralErrorComponent",
  computed:{
    showError(){
      return this.$store.state.showGeneralMessage;

    },

    keys(){

      return this.$store.state.generalMessage && this.$store.state.generalMessage.errors ? Object.keys(this.$store.state.generalMessage.errors) : [];

    }

  },
  watch:{
    showError(){

    }
  }


}
</script>

<style scoped>

</style>