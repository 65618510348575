<template>
  <v-row>
    <v-col cols="12">
      <v-card flat style="margin-top: 5em">
        <v-card-text>
          <center v-if="loading">

            <v-progress-circular color="blue" size="80" indeterminate></v-progress-circular>
          </center>
          <v-row v-else>
            <v-col cols="12" sm="5" class="pa-0">
              <v-card class="fill-height" flat>
                <v-img height="100%" src="/img/background.png">
                  <v-card-text class="vertical-center" v-if="SelectedPlan">
                    <p class="white--text text-center mt-4 mb-0" style="margin-top: 2em">Subscribe to Built Payroll
                      for</p>
                    <h1 class="white--text text-h5 font-weight-black text-center mt-0 mb-0">
                      {{ SelectedPlan.base_currency_code }}{{ SelectedPlan.amount | toMoney }}/Month</h1>
                    <p class="white--text text-center mb-0 mt-0">Billed at least quarterly</p>
                    <h3 class="yellow--text text-center mt-6">For further inquiries contact sales on:
                      <strong>0308040007</strong></h3>

                  </v-card-text>
                </v-img>

              </v-card>

            </v-col>

            <v-col cols="12" sm="7" class="text-center pa-4">
              <v-card-text class="pa-5">

                <h2 class="text-h5 font-weight-light">Amount:{{ Currency }}{{ Amount | toMoney }}</h2>

                <h2 v-if="extraCost" class="text-h5 font-weight-light">Extra Cost:{{ Currency }}{{
                    extraCost | toMoney
                  }}</h2>


                <h2 v-if="discountAmount" class="text-h5 font-weight-light">Discount Amount({{ discount }}%):{{
                    Currency
                  }}{{
                    discountAmount | toMoney
                  }}</h2>

                <h2 v-if="discountAmount" style="text-decoration: line-through" class="text-h5 font-weight-light">Amount
                  Due:{{ Currency }}{{
                    totalAmount | toMoney
                  }}</h2>

                <h2 class="text-h4 font-weight-light">Amount Due:{{ Currency }}{{ AmountDue | toMoney }}</h2>

                <v-autocomplete
                    :items="cycles"
                    outlined
                    label="Billing Cycle"
                    class="mt-3"
                    v-model="cycle"
                >
                </v-autocomplete>

<!--                <p>Current number of employees: {{ currentEMployeeCount }}</p>-->
<!--                <p>Current Limit: {{ currentLimit }}</p>-->
<!--                <p class="font-weight-black">New Limit: {{ newLimit }}</p>-->
<!--                <p class="orange&#45;&#45;text">Each extra employee will cost:-->
<!--                  {{ Currency }}{{ AmountPerEmployee | toMoney }}</p>-->

<!--                <h2 v-if="extraEmployees" class="green&#45;&#45;text font-weight-black mb-3">{{ extraEmployees }} extra-->
<!--                  employees will cost {{ Currency }}{{ extraCost | toMoney }}</h2>-->

<!--                <v-text-field-->
<!--                    type="number"-->
<!--                    outlined-->
<!--                    min="0"-->
<!--                    label="Add more employees"-->
<!--                    v-model="extraEmployees"-->
<!--                    prepend-inner-icon="mdi-account-outline"-->
<!--                ></v-text-field>-->


                <v-btn :loading="progress" @click="initCardPayment()" block x-large color="blue darken-4" rounded
                       depressed dark>Pay With card
                </v-btn>
                <v-btn v-if="!progress" @click="momoCheckout=true" block x-large color="green" class="mt-3" rounded
                       depressed dark>Pay With {{ business.country.code === 'KE' ? 'Mpesa' : 'Mobile Money' }}
                </v-btn>
                <v-btn v-if="!progress" @click="showDialog=true" text block x-large class="mt-3" rounded depressed>Apply
                  Coupon
                </v-btn>

              </v-card-text>

            </v-col>


          </v-row>

        </v-card-text>
      </v-card>

      <v-dialog v-model="showDialog" width="400">
        <v-card>
          <v-card-title>
            Apply coupon
            <v-spacer></v-spacer>
            <v-btn @click="showDialog=false"
                   color="red lighten-5 red--text"
                   depressed
                   rounded
                   small
            >Close
              <v-icon color="red" small>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form ref="coupon_form">

              <v-text-field v-model="couponCode" :rules="$store.state.required_rules" outlined
                            label="Coupon code"></v-text-field>

            </v-form>

          </v-card-text>
          <v-card-actions>

            <v-btn :loading="progress" @click="submitCoupon" x-large block color="blue darken-4" dark rounded depressed>
              Submit
            </v-btn>

          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
          v-model="momoCheckout"
          :fullscreen="$vuetify.breakpoint.mobile"
          persistent
          width="500"
          v-if="SelectedPlan"
      >
        <v-card>
          <v-card-title class="font-weight-light">
            Pay with
            {{
              ["GH", "RW","SL"].includes(business.country.code)
                  ? "Mobile Money"
                  : "Mpesa"
            }}
            <v-spacer></v-spacer>
            <v-btn
                color="red lighten-5 red--text"
                depressed
                rounded
                small
                @click="momoCheckout = false"
            >close
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <checkout
              :account_id="0"
              :amount="AmountDue"
              :record_id="cycle"
              :meta-data="employeesExtension"
              :record_type="'subscription-' + SelectedPlan.name.toLowerCase()"
              @paymentComplete="send_success"
          ></checkout>
        </v-card>
      </v-dialog>

    </v-col>
  </v-row>

</template>

<script>
import {defineComponent} from 'vue'
import Checkout from "@/components/Checkout.vue";
import moment from "moment";

export default defineComponent({
  name: "SubscriptionComponent",
  components: {Checkout},
  data() {
    return {
      showDialog: false,
      plans: [],
      loading: false,
      progress: false,
      cycle: 3,
      defaultLimit: 20,
      discount: 0,
      currentEMployeeCount: 0,
      amountPerEmployeeUSD: 5,
      extraEmployees: 0,
      couponCode: "",
      momoCheckout: false,
    }
  },
  computed: {

    employeesExtension() {
      return {
        amount: this.extraCost,
        defaultNumber: 20,
        extraNumber: this.extraEmployees,
        totalNumber: this.newLimit,
      }
    },
    totalAmount() {

      return this.Amount + this.extraCost;

    },
    discountAmount() {

      const discount = Number(this.discount);

      const amount = discount <= 0 ? 0 : (discount / 100) * this.totalAmount;

      return Number(amount.toFixed(2));

    },
    newLimit() {
      return Number(this.extraEmployees) + Number(this.defaultLimit);
    },


    currentLimit() {

      if (this.currentEMployeeCount > this.defaultLimit) {
        return 0;
      }

      return this.defaultLimit - this.currentEMployeeCount;


    },

    extraCost() {

      return this.AmountPerEmployee * this.extraEmployees;

    },
    AmountPerEmployee() {

      if (!this.SelectedPlan) {
        return 0;

      }
      return this.SelectedPlan.rate.rate * this.amountPerEmployeeUSD;

    },

    AmountDue() {

      return (this.Amount + this.extraCost) - this.discountAmount;

    },


    Currency() {

      if (this.SelectedPlan) {
        return this.SelectedPlan.base_currency_code;
      }

      return "";
    },

    Amount() {
      if (this.SelectedPlan) {

        return this.SelectedPlan.amount * this.cycle;

      }


      return 0;

    },


    cycles() {

      let list = [];

      for (let i = 6; i <= 48; i++) {

        list.push({
          value: i,
          text: i + " Months"
        });

      }
      return list;

    },
    business() {
      return this.$store.state.user.current_business;
    },
    SelectedPlan() {

      return this.plans.find(item => {
        return item.name.toLowerCase() === 'starter'
      })

    },
    reference() {
      let text = "";
      let possible =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      let d = new Date();
      let timestamp = d.getTime();
      return "Subscription_" + timestamp + "" + text;
    },
  },
  methods: {

    send_success() {

      location.reload();

    },


    send_success_direct() {
      this.progress = true;
      let formData = new FormData();
      formData.append("startdate", moment().format("Y-M-D"));
      formData.append("enddate", moment().add(this.cycle, "months").format("Y-M-D"));
      formData.append("amount", this.AmountDue);
      formData.append("plan", this.SelectedPlan.name.toLowerCase());
      formData.append("coupon_applied", this.discount > 0);
      formData.append("coupon_code", this.couponCode);
      formData.append("billing_cycle", this.cycle);
      axios
          .post("/appsubcription", formData)
          .then(() => {
            location.reload();
            // this.finish_sub();
          })
          .catch(() => {
            this.progress = false;
            // this.error_msg =
            //     "Something went wrong, could not save your subscription, please try again";
            // this.error_message = true;
          });
    },
    initCardPayment() {
      this.progress = true;
      const payload = {
        amount: this.AmountDue,
        type: "subscription-" + this.SelectedPlan.name.toLowerCase(),
        type_id: this.cycle,
        reference: this.reference
      };

      axios.post("/collection", payload).then((res) => {
        this.progress = false;

        this.$launchFlutterwave({
          tx_ref: res.data.reference,
          amount: this.AmountDue,
          country: this.business.country.code,
          currency: this.SelectedPlan.base_currency_code,
          payment_options: "card,mobilemoney,ussd",
          customer: {
            email: this.$store.state.user.email,
            phonenumber: this.$store.state.user.phone_number,
            name: this.$store.state.user.first_name + " " + this.$store.state.user.last_name,
            billing_cycle: this.cycle,
            plan: this.SelectedPlan.name,
          },
          callback: function () {
          },
          customizations: {
            title: `Subscribe to the ${this.SelectedPlan.name} plan for ${this.SelectedPlan.base_currency_code} ${this.AmountDue}.00 every ${this.cycle} months`,
            description: this.$store.state.user.current_business.address,
            logo: "https://my.builtaccounting.com/img/app_name.png",
          },
        });
      });
    },

    submitCoupon() {

      if (this.$refs.coupon_form.validate()) {

        this.progress = true;
        const code = this.couponCode;

        const data = {
          code: code
        };

        axios.post("/coupon", data)
            .then(res => {
              this.progress = false;

              this.discount = res.data.discount;

              if (this.discount >= 100) {

                this.send_success_direct();

              } else {

                this.showDialog = false;

              }


            })
            .catch(() => {
              this.progress = false;
            })

      }

    },

    getEmployeeCount() {

      this.progress = true;

      axios.get("/employees/count")
          .then(res => {

            this.currentEMployeeCount = res.data.employee_count;
            this.progress = false;

          })
    },
    getPlans() {
      this.loading = true;
      axios.get("/subscriptionplans/" + this.business.country_id)
          .then(res => {

            this.plans = res.data;

            this.loading = false;
          })
    }
  },
  mounted() {
    this.getPlans();
    this.getEmployeeCount();
  }
})
</script>


<style scoped>
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
</style>