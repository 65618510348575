<template>
  <v-card>
    <v-card-title>
      <v-btn text rounded @click="$emit('back')"><v-icon>mdi-arrow-left</v-icon>{{ $t("main.back") }}</v-btn>
      <v-spacer></v-spacer>
      <h1 class="font-weight-light">Gross-to-net calculator (<flag iso="KE"></flag> Kenya)</h1>
    </v-card-title>
    <v-card-text>
      <v-row>

        <v-col cols="4">
          <v-form ref="payForm">
            <v-row dense>

              <v-col cols="12">

                <v-menu ref="menu1" v-model="dateMenu" :close-on-content-click="false" transition="scale-transition"
                  offset-y max-width="290px" min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field dense outlined label="DATE/PERIOD" readonly v-model="date" v-on="on" v-bind="attrs"
                      persistent-hint :disabled="progress"
                      hint="Due to changing rules, this date would be used to determine how to handle the calculations"></v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title @input="dateMenu = false" :disabled="progress"></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12">
                <v-text-field autofocus dense outlined label="BASIC SALARY" type="number" v-model="basic_amount"
                  :rules="$store.state.requiredRule" :disabled="progress"></v-text-field>
              </v-col>


              <v-col cols="12">

                <v-text-field dense outlined label="BENEFITS(BONUS,ALLOWANCE)" type="number" v-model="benefits"
                  :disabled="progress"></v-text-field>


              </v-col>

              <v-col cols="12">

                <v-text-field dense outlined label="OTHER PENSIONS" type="number" v-model="other_pensions"
                  :disabled="progress"></v-text-field>


              </v-col>

              <v-col cols="12">
                <v-row dense>
                  <v-col cols="8">
                    <h3 class="font-weight-light">
                      Deduct NSSF:
                    </h3>
                  </v-col>
                  <v-col cols="4">
                    <v-switch :disabled="progress" v-model="deduct_nssf" flat inset color="blue darken-4" class="ma-0"
                      dense></v-switch>
                  </v-col>
                </v-row>
              </v-col>


              <v-col cols="12" v-if="deduct_nssf">
                <v-select dense outlined label="NSSF RATES" :items="nssfRates" v-model="nssfRate"
                  :disabled="progress"></v-select>
              </v-col>

              <v-col cols="12">
                <v-row dense>
                  <v-col cols="8">
                    <h3 class="font-weight-light">
                      Deduct NHIF:
                    </h3>
                  </v-col>
                  <v-col cols="4">
                    <v-switch :disabled="progress" v-model="deduct_nhif" flat inset color="blue darken-4" class="ma-0"
                      dense></v-switch>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-btn :loading="progress" @click="calculate()" large color="green" dark rounded block>Calculate</v-btn>
              </v-col>

            </v-row>

          </v-form>
        </v-col>
        <v-divider vertical></v-divider>

        <v-col cols="8">
          <v-simple-table class="table table-bordered" fixed-header height="90vh">

            <tbody>




              <tr>
                <td colspan="2" class="bg-light text-center">
                  <i>Earnings</i>

                </td>

              </tr>

              <tr>
                <td>
                  <h3 class="font-weight-bold">Basic Salary:</h3>

                </td>
                <td class="text-right">

                  <h3 v-if="result">{{ currency }}{{ result.basic_pay | toMoney }}</h3>
                  <h3 v-else>{{ currency }}{{ 0 | toMoney }}</h3>

                </td>
              </tr>


              <tr>
                <td>
                  <h3 class="font-weight-bold">Benefits:</h3>

                </td>
                <td class="text-right">
                  <h3 v-if="result">{{ currency }}{{ result.benefits | toMoney }}</h3>
                  <h3 v-else>{{ currency }}{{ 0 | toMoney }}</h3>
                </td>

              </tr>



              <tr>
                <td>
                  <h3 class="font-weight-bold">Gross Salary:</h3>
                </td>
                <td class="text-right">
                  <h3 v-if="result">{{ currency }}{{ result.gross_pay | toMoney }}</h3>
                  <h3 v-else>{{ currency }}{{ 0 | toMoney }}</h3>
                </td>

              </tr>
              <tr>
                <td colspan="2" class="bg-light text-center">
                  <i>Deductions</i>

                </td>

              </tr>



              <tr>
                <td>
                  <h3 class="font-weight-bold">P.A.Y.E:</h3>
                </td>
                <td class="text-right">
                  <h3 v-if="result">{{ currency }}{{ result.net_paye | toMoney }}</h3>
                  <h3 v-else>{{ currency }}{{ 0 | toMoney }}</h3>
                </td>
              </tr>

              <tr>
                <td>
                  <h3 class="font-weight-bold">NSSF:</h3>
                </td>
                <td class="text-right">
                  <h3 v-if="result">{{ currency }}{{ result.nssf | toMoney }}</h3>
                  <h3 v-else>{{ currency }}{{ 0 | toMoney }}</h3>
                </td>

              </tr>


              <tr>
                <td>
                  <h3 class="font-weight-bold">NHIF:</h3>
                </td>
                <td class="text-right">
                  <h3 v-if="result">{{ currency }}{{ result.nhif | toMoney }}</h3>
                  <h3 v-else>{{ currency }}{{ 0 | toMoney }}</h3>
                </td>

              </tr>
              <tr>
                <td>
                  <h3 class="font-weight-bold">Other Pensions:</h3>
                </td>
                <td class="text-right">
                  <h3 v-if="result">{{ currency }}{{ result.other_pensions | toMoney }}</h3>
                  <h3 v-else>{{ currency }}{{ 0 | toMoney }}</h3>
                </td>

              </tr>

              <tr>
                <td>
                  <h3 class="font-weight-bold">Total Deductions:</h3>
                </td>
                <td class="text-right">
                  <h3 v-if="result">{{ currency }}{{ result.total_deductions | toMoney }}</h3>
                  <h3 v-else>{{ currency }}{{ 0 | toMoney }}</h3>
                </td>

              </tr>

              <tr class="bg-light">
                <td>
                  <h2 class="font-weight-light">Net Pay:</h2>
                </td>
                <td class="text-right">
                  <h3 v-if="result">{{ currency }}{{ result.net_pay | toMoney }}</h3>
                  <h3 v-else>{{ currency }}{{ 0 | toMoney }}</h3>
                </td>

              </tr>

              <tr>
                <td colspan="2" class="bg-light text-center">
                  <i>Taxations</i>

                </td>

              </tr>

              <tr>
                <td>
                  <h3 class="font-weight-bold">Chargeable Pay:</h3>
                </td>
                <td class="text-right">
                  <h3 v-if="result">{{ currency }}{{ result.chargeable_income | toMoney }}</h3>
                  <h3 v-else>{{ currency }}{{ 0 | toMoney }}</h3>
                </td>

              </tr>


              <tr>
                <td>
                  <h3 class="font-weight-bold">Tax Charged:</h3>
                </td>
                <td class="text-right">
                  <h3 v-if="result">{{ currency }}{{ result.net_paye + result.personal_relief + result.nhif_relief | toMoney }}
                  </h3>
                  <h3 v-else>{{ currency }}{{ 0 | toMoney }}</h3>
                </td>

              </tr>

              <tr>
                <td>
                  <h3 class="font-weight-bold">Personal Relief:</h3>
                </td>
                <td class="text-right">
                  <h3 v-if="result">{{ currency }}{{ result.personal_relief | toMoney }}</h3>
                  <h3 v-else>{{ currency }}{{ 0 | toMoney }}</h3>
                </td>

              </tr>
              <tr>
                <td>
                  <h3 class="font-weight-bold">NHIF Relief(15%):</h3>
                </td>
                <td class="text-right">
                  <h3 v-if="result">{{ currency }}{{ result.nhif_relief | toMoney }}</h3>
                  <h3 v-else>{{ currency }}{{ 0 | toMoney }}</h3>
                </td>

              </tr>

              <tr>
                <td>
                  <h3 class="font-weight-bold">Net Tax:</h3>
                </td>
                <td class="text-right">
                  <h3 v-if="result">{{ currency }}{{ result.net_paye | toMoney }}</h3>
                  <h3 v-else>{{ currency }}{{ 0 | toMoney }}</h3>
                </td>

              </tr>

              <tr>
                <td>
                  <h3 class="font-weight-bold">Pension Relief:</h3>
                </td>
                <td class="text-right">
                  <h3 v-if="result">{{ currency }}{{ result.pension_relief | toMoney }}</h3>
                  <h3 v-else>{{ currency }}{{ 0 | toMoney }}</h3>
                </td>

              </tr>





            </tbody>
          </v-simple-table>

        </v-col>
      </v-row>

      <v-snackbar color="red" v-model="error">{{ error_message }}</v-snackbar>


    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  name: "KenyaPayroll",
  data() {
    return {
      currency: "KSH",
      other_pensions: 0,
      nssfRate: 'new_method',
      error: false,
      error_message: "",
      result: null,
      basic_amount: 0,
      benefits: 0,
      date: "",
      dateMenu: false,
      status: "resident",
      deduct_nssf: false,
      deduct_nhif: false,
      progress: false,
      nssfRates: [
        {
          value: "new_method",
          text: "New rates(tiered)"
        },
        {
          value: "old_method",
          text: "Old rate (KSH200)"
        }
      ],
      statuses: [
        {
          text: "Resident",
          value: "resident"
        },
        {
          text: "Non-resident",
          value: "nonresident"
        },

      ]
    }

  },
  methods: {
    calculate() {
      if (this.$refs.payForm.validate()) {
        this.progress = true;
        const formData = new FormData();
        formData.append("basic_pay", this.basic_amount);
        formData.append("benefits", this.benefits);
        formData.append("status", this.status);
        formData.append("date", this.date);
        formData.append("deduct_nssf", this.deduct_nssf);
        formData.append("deduct_nhif", this.deduct_nhif);
        formData.append("nssf_rate", this.nssfRate);
        formData.append("other_pensions", this.other_pensions);
        // eslint-disable-next-line no-undef
        axios.post("/payroll/ke/gross-to-net", formData)
          .then(res => {
            this.result = res.data.data;

            this.progress = false;

          })
          .catch(() => {

            this.progress = false;
            // this.error_message="Something went wrong, please try again";
            // this.error=true;


          });



      }

    }
  },
  mounted() {
    this.date = moment().format("Y-MM-DD");
  }
}
</script>

<style scoped></style>