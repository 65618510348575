<template>
  <v-card flat>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-btn rounded depressed @click="$emit('done')" color="red lighten-5 red--text">Close
        <v-icon small color="red">mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" sm="6" class="mx-auto">
          <v-stepper class="mt-4" elevation="0" v-model="step">
            <v-stepper-items>
              <v-stepper-content step="1" style="border: 1px solid lightgrey; border-radius: 15px;">
                <center>

                  <div class="text-center"
                       style="border-radius: 50%; width: 100px; height: 100px; background-color: rgba(161,212,252,0.5)">
                    <v-icon color="blue" size="100" class="ma-auto mt-4" dark>mdi-lightbulb-outline</v-icon>
                  </div>
                </center>
                <h1 class="font-weight-black text-h4 mt-5 ml-5">Let's setup your disbursement account</h1>
                <v-list class="mt-3">

                  <v-list-item three-line>
                    <v-list-item-avatar color="amber lighten-5" rounded="lg">
                      <v-icon large color="amber">mdi-bank-check</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-h5 font-weight-black">Setup employees' bank accounts
                      </v-list-item-title>
                      <v-list-item-subtitle>Setup employees with a wide range of supported bank accounts including mobile money
                        wallets
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item three-line>
                    <v-list-item-avatar rounded="lg" color="blue lighten-5">
                      <v-icon large color="blue">mdi-file-document-multiple-outline</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-h5 font-weight-black">Generate Payslips</v-list-item-title>
                      <v-list-item-subtitle>Generate payslips for all active employees for a month
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>


                  <v-list-item three-line>
                    <v-list-item-avatar rounded="lg" color="green lighten-5">
                      <v-icon large color="green">mdi-bank-transfer-out</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-h5 font-weight-black">Pay Salaries</v-list-item-title>
                      <v-list-item-subtitle>Disburse salaries to employees</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>


                </v-list>

                <v-btn @click="step=2" block x-large depressed color="blue darken-4" dark rounded>Get Started
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>

              </v-stepper-content>
              <v-stepper-content step="2">
                <v-card flat>
                  <v-card-title>
                    <v-spacer></v-spacer>
                    <v-img
                        width="20px"
                        src="/img/icons/personal_infor.svg"
                    ></v-img>
                    <h1 class="font-weight-light">Your personal details</h1>
                    <v-spacer></v-spacer>
                  </v-card-title>

                  <v-form ref="form1">
                    <v-col cols="12" sm="8" class="mx-auto">
                      <v-alert type="info" color="blue lighten-5 blue--text">
                        <h4>
                          Make sure your first name, last name and date of birth
                          are the same on your Ghana card
                        </h4>
                      </v-alert>
                      <v-text-field
                          outlined
                          label="First Name"
                          v-model="first_name"
                          :rules="requiredRules"
                          autofocus
                      ></v-text-field>
                      <v-text-field
                          outlined
                          label="Last Name"
                          v-model="last_name"
                          :rules="requiredRules"
                      ></v-text-field>

                      <v-menu
                          v-model="datemenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                          readonly
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                              v-model="birthdate"
                              :label="$t('main.date_of_birth')"
                              v-on="on"
                              outlined
                              readonly
                              :rules="requiredRules"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="birthdate"
                            no-title
                            @input="datemenu = false"
                        ></v-date-picker>
                      </v-menu>

                      <v-text-field
                          label="Ghana card personal number"
                          v-model="id_number"
                          outlined
                          v-if="id_type"
                          :rules="requiredRules"
                      ></v-text-field>

                      <v-file-input
                          accept="image/*"
                          :rules="requiredRules"
                          v-show="false"
                          id="cardUpload"
                          v-model="ghcard"
                          ref="cardUpload"
                      ></v-file-input>
                      <v-card
                          :img="ghcardPreview"
                          @click="triggerUpload"
                          outlined
                          height="200px"
                          class="mb-5"
                      >
                        <v-card-title v-if="ghcardPreview">
                          <v-spacer></v-spacer>
                          <v-btn
                              small
                              depressed
                              fab
                              color="red lighten-5 red--text"
                              @click="
                            ghcardPreview = null;
                            ghcard = null;
                          "
                          >
                            <v-icon>mdi-delete-outline</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text v-if="!ghcardPreview">
                          <center>
                            <v-icon x-large color="grey"
                            >mdi-upload-outline
                            </v-icon
                            >
                            <br/>
                            <strong>Click to upload Ghana card</strong><br/>
                            <small
                            >Upload a scan or photo of the front side of your
                              Ghana card, this card must have the same
                              number,names and date of birth as the ones
                              provided</small
                            >
                          </center>
                        </v-card-text>
                      </v-card>

                      <v-text-field
                          outlined
                          type="email"
                          label="Email"
                          v-model="email"
                          :rules="requiredRules"
                          disabled
                      ></v-text-field>

                      <v-text-field
                          type="tel"
                          outlined
                          v-model="phone_number"
                          label="Phone Number"
                          :rules="requiredRules"
                      ></v-text-field>

                      <v-btn
                          @click="to_step_two"
                          class="mt-3"
                          color="blue darken-4"
                          block
                          dark
                          depressed
                          rounded
                          x-large
                      >Continue
                        <v-icon>mdi-arrow-right</v-icon>
                      </v-btn>
                    </v-col>
                  </v-form>
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-card flat>
                  <v-card-title>
                    <v-spacer></v-spacer>
                    <v-img width="20px" src="/img/icons/profile_view.svg"></v-img>
                    <h1 class="font-weight-light">Business Information</h1>
                    <v-spacer></v-spacer>
                  </v-card-title>

                  <v-form ref="form2">
                    <v-col cols="12" sm="8" class="mx-auto">
                      <v-text-field
                          outlined
                          label="Business TIN"
                          v-model="business_tin"
                          :rules="requiredRules"
                          v-if="business_registered"
                          :disabled="progress"
                      ></v-text-field>

                      <!--                    <v-btn @click="resolveTIN" :loading="resolvingTIN" color="blue lighten-5 blue&#45;&#45;text" rounded depressed block large>Verify your business TIN</v-btn>-->

                      <v-text-field
                          outlined
                          label="Legal Business Name"
                          v-model="business_name"
                          :rules="requiredRules"
                          :disabled="progress"
                      ></v-text-field>

                      <v-select
                          v-if="business_registered"
                          outlined
                          label="Business Type"
                          :items="reg_types"
                          v-model="business_reg_type"
                          :rules="requiredRules"
                          :disabled="progress"
                      ></v-select>

                      <v-file-input
                          outlined
                          label="Upload Form A"
                          color="blue"
                          v-if="business_registered && show_from_a"
                          v-model="form_d"
                          :disabled="progress"
                          dense
                          :rules="$store.state.requiredRule"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                              v-if="index < 2"
                              color="blue darken-4"
                              dark
                              label
                              small
                          >
                            {{ text }}
                          </v-chip>
                        </template>
                      </v-file-input>
                      <v-file-input
                          outlined
                          label="Upload Form B"
                          color="blue"
                          v-if="business_registered && show_form_b"
                          v-model="form_b"
                          dense
                          :disabled="progress"
                          :rules="$store.state.requiredRule"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                              v-if="index < 2"
                              color="blue darken-4"
                              dark
                              label
                              small
                          >
                            {{ text }}
                          </v-chip>
                        </template>
                      </v-file-input>

                      <v-file-input
                          outlined
                          label="Upload Form 3"
                          color="blue"
                          dense
                          v-if="business_registered && show_from3"
                          v-model="form_3"
                          :disabled="progress"
                          :rules="$store.state.requiredRule"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                              v-if="index < 2"
                              color="blue darken-4"
                              dark
                              label
                              small
                          >
                            {{ text }}
                          </v-chip>
                        </template>
                      </v-file-input>

                      <v-file-input
                          outlined
                          label="Upload Form 21"
                          color="blue"
                          v-if="business_registered && show_form_21"
                          v-model="form_21"
                          dense
                          :disabled="progress"
                          :rules="$store.state.requiredRule"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                              v-if="index < 2"
                              color="blue darken-4"
                              dark
                              label
                              small
                          >
                            {{ text }}
                          </v-chip>
                        </template>
                      </v-file-input>

                      <v-file-input
                          outlined
                          label="Business Certificate"
                          color="blue"
                          v-if="business_registered"
                          v-model="business_certificate"
                          :disabled="progress"
                          dense
                          :rules="$store.state.requiredRule"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                              v-if="index < 2"
                              color="blue darken-4"
                              dark
                              label
                              small
                          >
                            {{ text }}
                          </v-chip>
                        </template>
                      </v-file-input>

                      <v-btn
                          color="blue darken-4"
                          class="mb-3"
                          text
                          block
                          dark
                          rounded
                          large
                          :disabled="progress"
                          @click="step = 2"
                      >
                        <v-icon>mdi-arrow-left</v-icon>
                        Back
                      </v-btn>

                      <v-btn
                          x-large
                          rounded
                          depressed
                          block
                          color="blue darken-4"
                          dark
                          @click="getAccountSection"
                          :loading="progress"
                      >Continue
                        <v-icon>mdi-arrow-right</v-icon>
                      </v-btn>
                    </v-col>
                  </v-form>
                </v-card>
              </v-stepper-content>
              <v-stepper-content step="4">
                <v-card>
                  <v-row>
                    <v-col cols="12" sm="6" class="mx-auto">
                      <center>
                        <h2 class="font-weight-light">
                          Setup your withdrawal Account
                        </h2>
                        <small
                        >This account would be the recipient of all withdrawals
                          from your Wallet</small
                        >
                        <h3><strong>Account Name:</strong>{{ account_name }}</h3>
                      </center>
                      <v-row justify="center" class="mt-4 mb-4">
                        <v-btn-toggle
                            v-model="account_toggle"
                            mandatory
                            rounded
                            color="blue"
                        >
                          <v-btn depressed> Mobile Money Account</v-btn>
                          <v-btn depressed> Bank Account</v-btn>
                        </v-btn-toggle>
                      </v-row>

                      <v-form v-if="account_toggle === 0">
                        <v-select
                            :items="networks"
                            outlined
                            v-model="code"
                            label="Select Network"
                            :rules="$store.state.requiredRule"
                            @change="resolveAccount"
                            :disabled="resolving"
                        ></v-select>

                        <v-text-field
                            v-model="account_number"
                            outlined
                            label="Enter phone number"
                            :rules="$store.state.requiredRule"
                            @blur="resolveAccount"
                            type="tel"
                            :disabled="resolving"
                        ></v-text-field>
                      </v-form>
                      <v-form v-else>
                        <v-autocomplete
                            :items="banks"
                            item-text="name"
                            item-value="code"
                            outlined
                            label="Select Bank"
                            :rules="$store.state.requiredRule"
                            @change="resolveAccount"
                            v-model="code"
                            :disabled="resolving"
                        ></v-autocomplete>

                        <v-text-field
                            outlined
                            label="Account number"
                            :rules="$store.state.requiredRule"
                            v-model="account_number"
                            type="tel"
                            @blur="resolveAccount"
                            :disabled="resolving"
                        ></v-text-field>
                      </v-form>

                      <v-list-item>
                        <v-list-item-action>
                          <v-checkbox v-model="acceptedTerms"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Accept Built Wallet's
                            <a
                                href="https://built.africa/gh/wallet&agreement"
                                target="_blank"
                            >Terms & conditions</a
                            >
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-btn
                          color="blue darken-4"
                          class="mb-3"
                          text
                          block
                          dark
                          rounded
                          large
                          :loading="progress"
                          @click="step = 3"
                          :disabled="resolving"
                      >
                        <v-icon>mdi-arrow-left</v-icon>
                        Back
                      </v-btn>

                      <v-btn
                          x-large
                          rounded
                          depressed
                          block
                          color="blue darken-4"
                          :dark="acceptedTerms"
                          @click="signup"
                          :loading="progress"
                          :disabled="resolving || !acceptedTerms"
                          v-if="account_name"
                      >Finish
                        <v-icon>mdi-check-circle</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="5">
                <v-row>
                  <v-col cols="12" sm="6" class="mx-auto text-center">
                    <center>
                      <v-img src="/img/icons/finished_alt.svg"></v-img>
                    </center>

                    <h1 class="font-weight-light">
                      You have successfully setup your disbursement account for {{ business_name }}
                    </h1>
                    <h3 class="font-weight-light">
                      Your information is currently under review, your Wallet
                      would be fully active after approval.
                      When it is approved you will be able to pay salaries directly from your account
                    </h3>
                    <v-btn
                        dark
                        block
                        color="blue darken-4"
                        rounded
                        depressed
                        large
                        @click="done"
                    >Done
                    </v-btn
                    >
                  </v-col>
                </v-row>
              </v-stepper-content>

            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>

    </v-card-text>
  </v-card>

</template>
<script>
import {defineComponent} from 'vue'

export default defineComponent({
      name: "WalletSetupComponent",
      data() {
        return {
          step: 1,
          business_name: "",
          business_tin: "",
          business_reg_type: "",
          business_registered: true,
          first_name: "",
          last_name: "",
          email: "",
          phone_number: "",
          id_number: "",
          id_type: "ghana_card",
          form_d: null,
          form_b: null,
          form_3: null,
          form_21: null,
          birthdate: "",
          datemenu: false,
          acceptedTerms: false,
          resolvingTIN: false,
          business_certificate: null,
          ghcard: null,
          ghcardPreview: null,
          resolving: false,
          error: false,
          progress: false,
          error_message: "",
          account_toggle: 0,
          account_name: "",
          code: "",
          account_number: "",
          banks: [],
          id_types: [
            {
              text: "Passport",
              value: "passport",
            },
            {
              text: "Ghana card",
              value: "ghana_card",
            },
            {
              text: "Driver's License",
              value: "driver_license",
            },
          ],
          networks: [
            {
              text: "AirtelTigo",
              value: "AIRTEL",
            },
            {
              text: "MTN",
              value: "MTN",
            },
            {
              text: "Vodafone",
              value: "VODAFONE",
            },
          ],
          reg_types: [
            {
              text: "Sole Proprietorship",
              value: "sole_proprietorship",
            },

            {
              text: "Partnership",
              value: "partnership",
            },

            {
              text: "Company by shares",
              value: "company_by_shares",
            },

            {
              text: "Company by guarantee",
              value: "company_by_guarantee",
            },

            {
              text: "External company",
              value: "external_company",
            },
          ],
          requiredRules: [
            (v) => !!v || this.$t("main.required"),
            //v => (v && v.length <= 10) || 'Name must be less than 10 characters',
          ],
        }
      },
      watch: {
        account_toggle() {
          this.code = "";
          this.account_number = "";
          this.account_name = "";
        },
        ghcard() {
          if (this.ghcard) {
            const fr = new FileReader();
            fr.readAsDataURL(this.ghcard);
            fr.onload = () => {
              this.ghcardPreview = fr.result;
            };
          }
        },
      },
      computed: {
        paystackNetwork() {

          return this.code;
        },

        paystackBankCode() {

          return this.code;

        },

        user() {
          return this.$store.state.user;
        },
        show_from3() {
          return (
              this.business_reg_type === "company_by_guarantee" ||
              this.business_reg_type === "company_by_shares"
          );
        },

        show_from_a() {
          return this.business_reg_type == "sole_proprietorship";
        },

        show_form_b() {
          return this.business_reg_type == "partnership";
        },

        show_form_21() {
          return this.business_reg_type == "external_company";
        },
      },
      methods: {
        resolveTIN() {
          this.resolvingTIN = true;
          axios.get("/api/resolve/tin/" + this.business_tin).then(() => {
            this.resolvingTIN = false;
          });
        },

        triggerUpload() {
          document.getElementById("cardUpload").click();
        },

        resolveAccount() {
          if (this.code && this.account_number) {
            this.resolving = true;
            let code = "";
            this.error = false;

            if (this.account_toggle === 0) {
              code = this.paystackNetwork;
            } else {
              code = this.paystackBankCode;
            }

            axios
                .get(
                    "/resolveaccount/?bank_code=" +
                    code +
                    "&account_number=" +
                    this.account_number
                )
                .then((res) => {
                  this.account_name = res.data.account_name;
                  this.resolving = false;
                })
                .catch((error) => {
                  this.account_name = "";
                  this.resolving = false;

                  this.error_message =
                      error.response.status == 302
                          ? error.response.data
                          : "We could not resolve this mobile money account";
                  this.error = true;
                });
          }
        },

        to_step_two() {
          if (this.$refs.form1.validate()) {
            this.step = 3;
          }
        },
        getAccountSection() {
          if (this.$refs.form2.validate()) {
            this.step = 4;
          }
        },
        signup() {
          if (!this.ghcard) {
            this.error_message =
                "You have not attached a photo of your Ghana card, please attach the required ID to continue";
            this.error = true;
            return;
          }

          if (this.$refs.form2.validate()) {
            this.progress = true;

            const formData = new FormData();
            formData.append("first_name", this.first_name);
            formData.append("last_name", this.last_name);
            formData.append("business_certificate", this.business_certificate);
            formData.append("gh_card_photo", this.ghcard);
            formData.append("birth_date", this.birthdate);
            formData.append("id_type", this.id_type);
            formData.append("id_number", this.id_number);
            formData.append("phone_number", this.phone_number);
            formData.append("business_name", this.business_name);
            formData.append("business_registered", this.business_registered);
            formData.append("business_tin", this.business_tin);
            formData.append("business_reg_type", this.business_reg_type);
            formData.append("form_d", this.form_d);
            formData.append("form_b", this.form_b);
            formData.append("form_3", this.form_3);
            formData.append("form_21", this.form_21);
            formData.append("email", this.email);
            formData.append("account_name", this.account_name);
            formData.append("account_number", this.account_number);
            formData.append("code", this.code);
            formData.append(
                "account_type",
                this.account_toggle == 0 ? "mobilemoney" : "bank"
            );

            axios
                .post("/bw/signup", formData)
                .then(() => {
                  this.progress = false;

                  this.step = 5;
                })
                .catch((err) => {
                  this.progress = false;
                  this.error_message =
                      err.response.status == 302
                          ? err.response.data
                          : "Something went wrong, could not create wallet";
                  this.error = true;
                });
          }
        },
        done() {
          location.reload();
        },
      },

      mounted() {
        this.email = this.user.email;
        const banks = require("@/payswitchBanks.json");
        this.banks = banks.banks;
      },
    },
)
</script>

<style scoped>

</style>