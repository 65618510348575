<template>
<v-card-text>
  <v-alert type="warning" v-if="step==1 && !suported">
    <p>
      We could not automatically determine your calculator because your country is not currently supported however you can
      select from the available calculators.
    </p>
  </v-alert>
 <v-stepper v-model="step" style="box-shadow: none; background-color: transparent; padding: 0;">
   <v-stepper-items class="p-0">
     <v-stepper-content step="1" class="p-0">

       <v-row>
         <v-col
             cols="12"
             sm="4"
             v-for="country in countries"
             :key="country.code"
         >
           <v-card flat class="text-center pa-5" @click="step=country.step">
               <flag style="width: 100px; height: 70px" :iso="country.code"></flag>
               <h2 class="font-weight-bold">{{country.name}}</h2>
             <small class="text-muted">Select to calculate gross-to-net in {{country.name}}</small>
           </v-card>

         </v-col>


       </v-row>

     </v-stepper-content>

     <v-stepper-content step="2" class="p-0">
       <ghana-payroll @back="step=1"></ghana-payroll>
     </v-stepper-content>

     <v-stepper-content step="3" class="p-0">
       <kenya-payroll @back="step=1" ></kenya-payroll>
     </v-stepper-content>
     <v-stepper-content step="4" class="p-0">
       <s-l-payroll @back="step=1"></s-l-payroll>
     </v-stepper-content>

   </v-stepper-items>
 </v-stepper>


</v-card-text>
</template>

<script>
import GhanaPayroll from "./GhanaPayroll";
import KenyaPayroll from "./KenyaPayroll";
import SLPayroll from "./SLPayroll";
export default {
  name: "GrossToNetCalculator",
  components:{
    SLPayroll,
    KenyaPayroll,
    GhanaPayroll,
  },
  data(){
    return{
      contryCodes:["GH","KE","SL"],
      step:1,
      countries:[
        {
          name:"Ghana",
          code:"GH",
          step:2
        },
        {
          name:"Kenya",
          code:"KE",
          step:3
        },
        {
          name:"Sierra Leone",
          code:"SL",
          step:4
        }

      ]

    }
  },
  computed:{
    business(){
      return this.$store.state.user.current_business;
    },
    suported(){

      return this.contryCodes.includes(this.business.country.code);

    }
  },
  mounted() {
    if (this.suported){
      const countryCode = this.business.country.code;
      if (countryCode.toLowerCase()==='gh'){
        this.step=2
      }else if (countryCode.toLowerCase()==='ke'){
        this.step=3
      }else {

        this.step=4;
      }


    }
  }
}
</script>

<style scoped>

</style>