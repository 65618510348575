import Cookies from "js-cookie";

export const abbreviateNumber = (number) => {
	const SI_SYMBOL = ["", "k", "M", "B", "T", "P", "E"];

	const absNumber = Math.abs(number);
	// what tier? (determines SI symbol)
	const tier = (Math.log10(absNumber) / 3) | 0;

	// if zero, we don't need a suffix

	if (tier === 0) {
		return number < 0 ? "(" + absNumber.toFixed(2) + ")" : absNumber.toFixed(2);
	}

	// get suffix and determine scale
	const suffix = SI_SYMBOL[tier];
	const scale = Math.pow(10, tier * 3);

	// scale the number
	const scaled = absNumber / scale;
	const final = scaled.toFixed(2) + suffix;

	return number < 0 ? "(" + final + ")" : final;
};

export const setAccessToken = (token) => {
	Cookies.set("access_token", token, { expires: 7 });
};
export const getAccessToken = () => {
	return Cookies.get("access_token");
};
